import React from 'react';
import { useTreatmentPlan } from '../treatmentPlanContext';
import { Typography, CardContent, Box } from '@mui/material';
import palette from 'src/theme/palette';

const SingleSummary = ({ objectiveId, goalId }) => {
  const {
    objectives,
    interventions,
    selectedBehaviors,
    selectedProblems,
    goals,
    selectedGoals,
    selectedInterventions,
    behaviors,
    problems,
    selectedDiagnosis,
  } = useTreatmentPlan();

  const behaviorId = Object.keys(selectedGoals).find((key) =>
    selectedGoals[key].some((item) => item.id == goalId)
  );
  const goal = goals[behaviorId]?.find((b) => b.id == goalId)?.name;

  const problemId = Object.keys(selectedBehaviors).find((key) =>
    selectedBehaviors[key].some((item) => item.id == behaviorId)
  );
  const diagnosisId = Object.keys(selectedProblems).find((key) =>
    selectedProblems[key].some((item) => item.id == problemId)
  );
  const problem = problems[diagnosisId]?.find((b) => b.id == problemId)?.name;

  const behavior = behaviors[problemId]?.find((b) => b.id == behaviorId)?.name;
  const diagnosis = selectedDiagnosis?.find((b) => b.id == diagnosisId);

  return (
    <Box
      key={objectiveId}
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0px',
        padding: '10px 0px',
        borderBottom: `1px dashed ${palette.border.main}`,
      }}
    >
      <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
        <strong>Diagnosis: </strong>
        {diagnosis?.name}
        {diagnosis?.description ? ` (${diagnosis.description})` : ''}{' '}
      </Typography>
      <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
        <strong>Problem: </strong>
        {problem}
      </Typography>
      <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
        <strong>Behavior: </strong>
        {behavior}
      </Typography>
      <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
        <strong>Goal: </strong>
        {goal}
      </Typography>
      <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
        <strong>Objective: </strong>
        {objectives[goalId]?.find((o) => o.id === objectiveId)?.name}
      </Typography>
      {(selectedInterventions[objectiveId] || []).map((intervention) => (
        <Typography sx={{ fontSize: 12, fontWeight: 400, lineHeight: '18px' }}>
          <strong>Intervention:</strong>
          {interventions[objectiveId]?.find((o) => o.id === intervention.id)?.name}
        </Typography>
      ))}
    </Box>
  );
};
const TreatmentPlanSummary = () => {
  const { selectedObjectives } = useTreatmentPlan();

  return (
    <div style={{ margin: '10px', overflowY: 'auto', maxHeight: '400px' }}>
      <CardContent style={{ padding: '12px' }}>
        {Object.entries(selectedObjectives).map(([goalId, objectiveIds]) =>
          objectiveIds.map((objectiveId) => (
            <SingleSummary goalId={goalId} objectiveId={objectiveId.id} />
          ))
        )}
      </CardContent>
    </div>
  );
};
export default TreatmentPlanSummary;
