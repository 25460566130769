import React from 'react'
import Box from 'src/components/Box'

const Page404Section = () => {
  return (
    <Box>

          </Box>

  )
}

export default Page404Section