/* eslint-disable jsx-a11y/img-redundant-alt */
import { API_URL } from 'src/api/constants';
import Switch from 'src/wiredComponent/Switch';
import TableTextRendrer from 'src/components/TableTextRendrer';
import { dateFormats } from './constants';
import { dateFormatter, getDateDiff, getFullName, getImageUrl } from './utils';
import person from 'src/assets/images/persone.png';
import { Button } from '@mui/material';
import TableDropDown from 'src/wiredComponent/DropDown';
import { join } from 'lodash';

const ASSISTANT_COLUMNS = [
  {
    label: 'Name',
    type: 'text',
    dataKey: 'firstName',
    sort: true,
    maxWidth: '10rem',
    render: ({ data }) => `${data?.firstName} ${data?.lastName}`
  },
  {
    label: 'Email',
    type: 'text',
    dataKey: 'email',
    maxWidth: '15rem',
  },
  {
    label: 'Status',
    dataKey: 'isActive',
    type: 'boolean',
    activeData: 'Active',
    inActiveData: 'InActive',
    width: '10px',
    api: API_URL.assistant,
    render: ({ data }) => <Switch rowData={data} api={API_URL.assistant} />,
  },
  {
    label: '',
  },
];
const PRACTITIONER_COLUMNS = [
  {
    label: 'Name',
    type: 'text',
    dataKey: 'firstName',
    sort: true,
    maxWidth: '10rem',
    render: ({ data }) => `${data?.firstName} ${data?.lastName}`
  },
  {
    label: 'Email',
    type: 'text',
    dataKey: 'email',
    maxWidth: '15rem',
  },
  {
    label: 'Status',
    dataKey: 'isActive',
    type: 'boolean',
    activeData: 'Active',
    inActiveData: 'InActive',
    width: '10px',
    render: ({ data }) => <Switch rowData={data} api={API_URL.practitioner} />,
  },
  {
    label: '',
  },
];
const CLINIC_ADMIN_COLUMNS = [
  {
    label: 'Name',
    type: 'text',
    dataKey: 'name',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Email',
    type: 'text',
    dataKey: 'email',
    maxWidth: '15rem',
  },
  {
    label: 'Status',
    dataKey: 'isActive',
    type: 'boolean',
    activeData: 'Active',
    inActiveData: 'InActive',
    api: API_URL.clinicAdmin,
    width: '10px',
    render: ({ data }) => <Switch rowData={data} api={API_URL.clinicAdmin} />,
  },
  {
    label: '',
  },
];
const CLINIC_COLUMNS = [
  {
    label: 'Name',
    type: 'text',
    dataKey: 'name',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Email',
    type: 'text',
    dataKey: 'email',
    maxWidth: '10rem',
  },
  {
    label: 'Address',
    dataKey: 'address.description',
    type: 'text',
    maxWidth: '14rem',
  },
  {
    label: 'Team',
    dataKey: 'team',
    type: 'text',
    maxWidth: '3rem',
  },
  {
    label: 'Status',
    dataKey: 'isActive',
    type: 'boolean',
    activeData: 'Active',
    inActiveData: 'InActive',
    width: '2rem',
  },
  {
    label: '',
  },
];
const PATIENT_COLUMNS = [
  {
    label: 'ID',
    type: 'text',
    dataKey: 'id',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Patient Name',
    type: 'text',
    dataKey: 'firstName',
    sort: true,
    maxWidth: '10rem',
    render:({data})=><div style={{display:'flex',gap:3.89,alignItems:'center'}}><img style={{height:32,width:32,borderRadius:50,objectFit:'cover'}} src={data?.file?.file ? getImageUrl(data?.file?.file):person } alt="patient image"/><TableTextRendrer>{getFullName(data)}</TableTextRendrer></div>
  },
  {
    label: 'Age',
    type: 'text',
    dataKey: 'age',
    maxWidth: '10rem',
    sort: true,
    render:({data})=><TableTextRendrer>{getDateDiff(data.dob,new Date(),{unit:'years'})}</TableTextRendrer>

  },
  {
    label: 'Gender',
    type: 'text',
    dataKey: 'age',
    maxWidth: '10rem',
    sort: true,
    render:({ data }) => {
      let displayText = '';
    
      if (data?.genderIdentityCode) {
        if (data.genderIdentityCode === 'another_gender_identity') {
          displayText = data?.anotherGenderIdentity || data?.genderIdentity?.name;
        } else {
          displayText = data?.genderIdentity?.name;
        }
      } else {
        displayText = data?.sexAtBirthCode === 'gender_at_birth_other'
          ? data?.otherSexAtBirth || data?.sexAtBirth?.name
          : data?.sexAtBirth?.name;
      }
    
      return <TableTextRendrer>{displayText || 'N/A'}</TableTextRendrer>;
    }

  },
  {
    label: 'Problems',
    type: 'text',
    dataKey: 'id',
    maxWidth: '10rem',
    render:({data})=>{
      const firstThreeProblems = data.problems.slice(0, 3);
      const firstThreeICDNames = firstThreeProblems.map(problem => `${problem?.ICD?.name}${problem?.ICD?.description?` (${problem?.ICD?.description})`:''}`).join(', ');
    return <TableTextRendrer style={{maxWidth:'15rem'}}>{firstThreeICDNames || 'N/A'}</TableTextRendrer>
  },

  },
  {
    label: 'Enrolled Date',
    dataKey: 'createdAt',
    type: 'date',
    sort: true,
    format: dateFormats.MMDDYYYY,
  },
  {
    label: 'Provider',
    type: 'text',
    dataKey: 'provider',
    maxWidth: '10rem',
    sort: true,
    render:({data})=><TableTextRendrer>{getFullName(data?.primaryProvider)}</TableTextRendrer>,
  },

];

const STAFF_COLUMNS = [
  {
    label: 'ID',
    type: 'text',
    dataKey: 'id',
    sort: true,
  },
  {
    label: 'Name',
    type: 'text',
    dataKey: 'name',
    render:({data})=><div style={{display:'flex',gap:3.89,alignItems:'center'}}><img style={{height:32,width:32,borderRadius:50,objectFit:'cover'}} src={data?.file?.file ? getImageUrl(data?.file?.file):person } alt="patient image"/><TableTextRendrer>{getFullName(data)}</TableTextRendrer></div> 
  },
  {
    label: 'Email',
    type: 'text',
    dataKey: 'email',
  },
  {
    label: 'Role',
    type: 'text',
    dataKey: 'roleId',
    render: ({ data }) => {
      console.log('data', data, data?.user);
      const roles = data?.user?.roles?.map((role) => role?.name).join(', ');
      return  (<TableTextRendrer>
        {roles || 'No roles assigned'}
      </TableTextRendrer>)
    }
  }
];

const INVOICE_COLUMNS = [
  {
    label: 'Invoice #',
    type: 'text',
    dataKey: 'invoiceNumber',
    maxWidth: '5rem',
    sort: true,
  },
  {
    label: 'Description',
    type: 'text',
    dataKey: 'note',
    maxWidth: '14rem',
  },
  {
    label: 'Due Date',
    dataKey: 'dueDate',
    type: 'date',
    format: dateFormats.MMDDYYYY,
  },
  {
    label: 'Status',
    dataKey: 'status',
    type: 'chips',
    labelAccessor: 'status',
  },
  {
    label: 'Amount',
    dataKey: 'invoiceAmount',
    type: 'text',
  },
];
const LAB_RADIOLOGY_COLUMNS = [
  {
    label: 'Order Id',
    type: 'text',
    dataKey: 'id',
    maxWidth: '6rem',
    sort: true,
  },
  {
    label: 'Patient',
    type: 'text',
    dataKey: 'patientId',
    sort: true,
    render:({data})=><TableTextRendrer style={{maxWidth:'8rem'}}>{getFullName(data.patient || {})}</TableTextRendrer>
  },

  {
    label: 'Prescriber',
    type: 'text',
    dataKey: 'providerId',
    sort: true,
    maxWidth: '10rem',
    render:({data})=><TableTextRendrer style={{maxWidth:'8rem'}}>{getFullName(data.provider || {})}</TableTextRendrer>

  },
  {
    label: 'Order Date',
    type: 'date',
    dataKey: 'createdAt',
    maxWidth: '10rem',
    sort: true,
    format: dateFormats.MMMDDYYYYHHMMSS,
  },
  {
    label: 'Lab Test',
    type: 'text',
    dataKey: 'lab',
    maxWidth: '5rem',
    render:({data})=><TableTextRendrer style={{maxWidth:'12rem'}}>{data?.laboratoryTests?.map(item=>`${item.name} ( CPT: ${item.cptCode} LOINC: ${item.loincCode}) - ${item?.description}`)?.join(',') || 'N/A'}</TableTextRendrer>

  },
  {
    label: 'Status',
    dataKey: 'statusCode',
    type: 'text',
    sort: true,
    render: ({ data }) => (
      <>
        <TableDropDown
          data={data?.status || {}}
          id={data?.id}
          api={`${API_URL.labsRadiology}/${data.id}`}
          code="status_code"
          dataKey="statusCode"
        />
      </>
    ),
  },
];

const ALLERGIES_COLUMNS = [
  {
    label: 'Allergent',
    type: 'text',
    dataKey: 'allergy',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Severity',
    type: 'text',
    dataKey: 'severities.name',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Reaction',
    type: 'text',
    dataKey: 'reaction.name',
    maxWidth: '10rem',
    sort: true,
    render: ({ data }) => (
      <TableTextRendrer>
        {join(
          data?.reactions?.map((item) => item.name),
          ','
        )}
      </TableTextRendrer>
    ),
  },
  {
    label: 'Date Onset',
    dataKey: 'dateOfOnSet',
    type: 'date',
    sort: true,
    format: dateFormats.MMDDYYYY,
  },
];

const MEDICATION_COLUMNS = [
  {
    label: 'Medicine',
    maxWidth: '6rem',
    type: 'text',
    render: ({ data }) => {
      const title = data?.items
        ?.map((item) => `${item?.genericDrug?.name} (${item?.brandNameDrug?.name})`)
        .join(', ');
      return (
        <TableTextRendrer
          title={title}
          style={{maxWidth:'20rem'}}
        >
          {title}
        </TableTextRendrer>
      );
    },
  },
  {
    label: 'Prescriber',
    type: 'number',
    render: ({data}) => <TableTextRendrer style={{maxWidth:'15rem'}}>{getFullName(data?.prescriber)}</TableTextRendrer>
  },
  {
    label: 'Created on',
    type: 'date',
    dataKey: 'createdAt',
    format: dateFormats.MMMDDYYYYHHMMSS,
    sort: true,
    maxWidth: '10rem',
  },
];


const DIAGNOSIS_COLUMN = [
  {
    label: 'Diagnosis',
    type: 'text',
    dataKey: 'problem.name',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Diagnosis Description',
    type: 'text',
    dataKey: 'problem.description',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'ICD10',
    type: 'text',
    dataKey: 'ICD.name',
    maxWidth: '10rem',
    sort: true,
    render:({data})=>{
      return <TableTextRendrer style={{maxWidth:'15rem'}}>{`${data?.ICD?.name}${data?.ICD?.description?` (${data?.ICD?.description})`:''}`}</TableTextRendrer>
    }
  },
  {
    label: 'Type',
    dataKey: 'type.name',
    type: 'text',
    sort: true,
  },
  {
    label: 'Comments',
    dataKey: 'comments',
    type: 'text',
    sort: true,
    maxWidth: '10rem',
  },
  {
    label: 'Last Edited',
    dataKey: 'updatedAt',
    sort: true,
    render: ({ data }) => (
      <div>
        <TableTextRendrer>
          {dateFormatter(data.updatedAt, dateFormats.MMDDYYYYhhmmA)}
        </TableTextRendrer>
        <TableTextRendrer>
          {getFullName(data.updatedBy || data.createdBy)}
        </TableTextRendrer>
      </div>
    ),
  },
  {
    label: 'Status',
    dataKey: 'isActive',
    type: 'text',
    sort: true,
    render: ({ data }) => (
      <>
        <TableDropDown
          data={data.status || {}}
          id={data.id}
          api={`${API_URL.diagnosis}/${data.id}`}
          code="diagnosis_status"
          dataKey="statusCode"
        />
      </>
    ),
  },
];

const VITALS_COLUMN = [
  {
    label: 'Blood Pressure',
    type: 'text',
    dataKey: 'bpMM',
    sort: true,
    maxWidth: '4rem',
    render: ({ data }) =>
      !!data.bpMM &&
      !!data.bphg && (
        <TableTextRendrer>{`${data.bpMM}/${data.bphg} mm/Hg`}</TableTextRendrer>
      ),
  },
  {
    label: 'Heart Rate',
    type: 'text',
    dataKey: 'heartRate',
    sort: true,
    maxWidth: '5rem',
    render: ({ data }) =>
      !!data.heartRate && (
        <TableTextRendrer>{`${data.heartRate} bpm`}</TableTextRendrer>
      ),
  },
  {
    label: 'Respiratory Rate',
    type: 'text',
    dataKey: 'respiratoryRate',
    sort: true,
    maxWidth: '6rem',
    render: ({ data }) =>
      !!data.respiratoryRate && (
        <TableTextRendrer>{`${data.respiratoryRate} bpm`}</TableTextRendrer>
      ),
  },
  {
    label: 'Temperature',
    type: 'text',
    dataKey: 'tempreature',
    sort: true,
    maxWidth: '5rem',
    render: ({ data }) =>
      !!data.tempreature && (
        <TableTextRendrer>{`${data.tempreature} °C`}</TableTextRendrer>
      ),
  },
  {
    label: 'Height',
    type: 'text',
    dataKey: 'height',
    sort: true,
    maxWidth: '3rem',
    render: ({ data }) => {
      if (!!data.height) {
        let heightInFeet = data.height;
        if (data.heightUnit === 'cm') {
          // Convert height from centimeters to feet and inches
          const feet = Math.floor(data.height / 30.48);
          const inches = Math.round((data.height % 30.48) / 2.54);
          heightInFeet = `${feet}'${inches}"`;
        } else if (data.heightUnit === 'in') {
          // If height unit is in inches, convert it to feet and inches
          const feet = Math.floor(data.height / 12);
          const inches = data.height % 12;
          heightInFeet = `${feet}'${inches}"`;
        } else if (data.heightUnit === 'ft') {
          // If height unit is already in feet, simply display it
          heightInFeet = `${data.height}'`;
        }
        return <TableTextRendrer>{heightInFeet}</TableTextRendrer>;
      }
      return null;
    },
  },
  {
    label: 'Weight',
    type: 'text',
    dataKey: 'weight',
    sort: true,
    maxWidth: '3rem',
    render: ({ data }) =>
      !!data.weight && (
        <TableTextRendrer>{`${data.weight} kg`}</TableTextRendrer>
      ),
  },
  {
    label: 'BMI',
    type: 'text',
    dataKey: 'bmi',
    sort: true,
    maxWidth: '2rem',
    render: ({ data }) =>
      !!data.bmi && <TableTextRendrer>{`${data.bmi}`}</TableTextRendrer>,
  },
  {
    label: 'SPO2',
    type: 'text',
    dataKey: 'spO2',
    sort: true,
    maxWidth: '3rem',
    render: ({ data }) =>
      !!data.spO2 && <TableTextRendrer>{`${data.spO2}%`}</TableTextRendrer>,
  },
  {
    label: 'Recorded',
    dataKey: 'recordDateTime',
    type: 'date',
    sort: true,
    maxWidth: '8rem',
    format: dateFormats.MMDDYYYYhhmmA,
  },
];


export {
  CLINIC_ADMIN_COLUMNS,
  ASSISTANT_COLUMNS,
  PRACTITIONER_COLUMNS,
  CLINIC_COLUMNS,
  PATIENT_COLUMNS,
  INVOICE_COLUMNS,
  LAB_RADIOLOGY_COLUMNS,
  ALLERGIES_COLUMNS,
  MEDICATION_COLUMNS,
  DIAGNOSIS_COLUMN,
  VITALS_COLUMN,
  STAFF_COLUMNS,
};
