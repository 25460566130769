/* eslint-disable no-underscore-dangle */
import EventEmitter from 'events';
import * as StatsUtilsDefault from './statsUtils';

const INITIAL_DELAY = 1000;
const INTERVAL = 2500;

const createCongestionLevelStats = (getPcStats, deps = {}) => {
  const StatsUtils = deps.StatsUtils || StatsUtilsDefault;
  const ee = new EventEmitter();
  let lastStats;

  const processStats = (stats) => {
    const parsedStats = StatsUtils.parseStats(stats);
    if (!lastStats) {
      lastStats = parsedStats;
      return;
    }

    const audioPacketLoss = StatsUtils.getAudioPacketLoss(parsedStats, lastStats);
    const videoPacketLoss = StatsUtils.getVideoPacketLoss(parsedStats, lastStats);

    const { bandwidth, videoResolution } = parsedStats;
    lastStats = parsedStats;

    ee.emit('statsAvailable', {
      audioPacketLoss,
      videoPacketLoss,
      bandwidth,
      videoResolution,
    });
  };

  const getStats = () => {
    getPcStats((err, stats) => {
      if (!err) {
        processStats(stats);
      }
    });
  };

  let cancelId;
  return Object.assign(ee, {
    start() {
      if (cancelId) {
        // already started
        return;
      }
      cancelId = setTimeout(() => {
        getStats();
        cancelId = setInterval(() => {
          getStats();
        }, INTERVAL);
      }, INITIAL_DELAY);
    },

    stop() {
    // Note that clearInterval and clearTimeout are interchangeable
      clearInterval(cancelId);
      cancelId = undefined;
      lastStats = undefined;
    },
  });
};

export default createCongestionLevelStats;
