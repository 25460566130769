import { regexCustomText, requiredField } from "src/lib/constants";

export const billingCodesFormGroups = [
    {
      inputType: 'text',
      type: 'text',
      name: 'name',
      required: requiredField,
      textLabel: 'Name',
      pattern: regexCustomText,
    },
    {
      inputType: 'text',
      type: 'number',
      name: 'qty',
      textLabel: 'Qty',
      pattern: regexCustomText,
      colSpan: 0.5,
      required: requiredField,
      disabled:true,
    },
    {
      inputType: 'text',
      type: 'number',
      name: 'price',
      textLabel: 'Price',
      pattern: regexCustomText,
      colSpan: 0.5,
      required: requiredField,
    },
    {
      type: 'text',
      label: 'Modifiers',
      colSpan: 0.5,
      required: requiredField,
  
      fields: [
        {
          inputType: 'text',
          type: 'number',
          name: 'modifier1',
          colSpan: 0.34,
          placeholder: 'Enter',
          required: requiredField,
        },
        {
          inputType: 'text',
          type: 'number',
          name: 'modifier2',
          colSpan: 0.34,
          placeholder: 'Enter',
          required: requiredField,
        },
        {
          inputType: 'text',
          type: 'number',
          name: 'modifier3',
          colSpan: 0.34,
          placeholder: 'Enter',
          required: requiredField,
        },
        {
          inputType: 'text',
          type: 'number',
          name: 'modifier4',
          colSpan: 0.34,
          placeholder: 'Enter',
          required: requiredField,
        },
      ],
    },
    {
        inputType: 'textArea',
        name: 'description',
        textLabel: 'Description',
        pattern: regexCustomText,
        colSpan: 1,
        required: requiredField,
      },
  ];