/* eslint-disable no-unused-vars */
import leslie from 'src/assets/images/leslie.png';
import women from 'src/assets/images/black-orange.png';
import rita from 'src/assets/images/rita.png';
import  whitemen  from 'src/assets/images/whitemen.png';
import meera from 'src/assets/images/meera.png'
import  del from 'src/assets/images/del.png';
import write from 'src/assets/images/write.png';
import view from 'src/assets/images/view.png';

export const Billing=[{
    title:[ "Date"," Patient ","Claimid ","BillingProvider  ", "Payer ", "ClaimAmount"],
    Date:"12/09/2022",
    Patient:"John",
    Claimid:"68456IF",
    BillingProvider:"Billing",
    Payer:"New York",
    ClaimAmount:"$ 80.00",

},{
    title:[ "Date"," Patient ","Claimid ","BillingProvider  ", "Payer ", "ClaimAmount"],
    Date:"12/09/2022",
    Patient:"John",
    Claimid:"68456IF",
    BillingProvider:"Billing",
    Payer:"New York",
    ClaimAmount:"$ 80.00",

},{
    title:[ "Date"," Patient ","Claimid ","BillingProvider  ", "Payer ", "ClaimAmount"],
    Date:"12/09/2022",
    Patient:"John",
    Claimid:"68456IF",
    BillingProvider:"Billing",
    Payer:"New York",
    ClaimAmount:"$ 80.00",

},{
    title:[ "Date"," Patient ","Claimid ","BillingProvider  ", "Payer ", "ClaimAmount"],
    Date:"12/09/2022",
    Patient:"John",
    Claimid:"68456IF",
    BillingProvider:"Billing",
    Payer:"New York",
    ClaimAmount:"$ 80.00",

},{
    title:[ "Date"," Patient ","Claimid ","BillingProvider  ", "Payer ", "ClaimAmount"],
    Date:"12/09/2022",
    Patient:"John",
    Claimid:"68456IF",
    BillingProvider:"Billing",
    Payer:"New York",
    ClaimAmount:"$ 80.00",

}]  

export const Labs=[{
    Name:"John",
    Test:"Anxiety",
    Result:"26",
    Date:"12/14/20202",
    Location:"New York",
    src1:write,
    src2:view ,
     src3: del 

},{
    Name:"John",
    Test:"Anxiety",
    Result:"26",
    Date:"12/14/20202",
    Location:"New York",
    src1:write,
    src2:view ,
     src3: del 

},{
    Name:"John",
    Test:"Anxiety",
    Result:"26",
    Date:"12/14/20202",
    Location:"New York",
    src1:write,
    src2:view ,
     src3: del 

},{
    Name:"John",
    Test:"Anxiety",
    Result:"26",
    Date:"12/14/20202",
    Location:"New York",
    src1:write,
  src2:view ,
   src3: del 

}]
export  const Taskarea =[
    { 
        id:1,
        src:meera,
    title: "Reach Out to Patient",
        name: "Eddie Lake",
    },
    { 
        id:2,
        src:women ,
    title: "Reach Out to Patient",
        name: "Eddie Lake",
    },
    { 
        id:3,
      src: whitemen,
    title: "Reach Out to Patient",
        name: "Eddie Lake",
    },
    { 
        id:4,
    src:rita,
    title: "Reach Out to Patient",
        name: "Eddie Lake",
    }
]

export const dataAppointSection = [
    {
        id:1,
        name: "Leslie Alexander",
        gender: "Female, 25",
        time: "April 12, 9.30 AM",
       src:leslie,
       accepted:false,
    },
    {
        id:2,
        name: "Leslie Alexander",
        gender: "Female, 25",
        time: "April 12, 9.30 AM",
       src:leslie,
       accepted:true,
    },
    {
        id:3,
        name: "Leslie Alexander",
        gender: "Female, 25",
        time: "April 12, 9.30 AM",
       src:leslie,
       accepted:true,
    },
    {
        id:4,
        name: "Leslie Alexander",
        gender: "Female, 25",
        time: "April 12, 9.30 AM",
       src:leslie,
       accepted:true,
    },

];

 export  const RecentPatients=[
    {
        id:1,
     src: leslie,         
     name:"Guy Hawkins",
        age:"Male, 25",
         time:"Today, 10:30 AM",
    },
    {
        id:2,
     src: leslie,         
     name:"Guy Hawkins",
        age:"Male, 25",
         time:"Today, 10:30 AM",
    },
    {
        id:3,
     src: leslie,         
     name:"Guy Hawkins",
        age:"Male, 25",
         time:"Today, 10:30 AM",
    },
    {
        id:4,
     src: leslie,         
     name:"Guy Hawkins",
        age:"Male, 25",
         time:"Today, 10:30 AM",
    },
 ]

export  const billing=[{
}]