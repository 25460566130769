import React, { useCallback, useEffect } from 'react';
import { useTreatmentPlan } from '../treatmentPlanContext';
import LoadingButton from 'src/components/CustomButton/loadingButton';
import CardActions from '@mui/material/CardActions';
import TreatmentPlanSummary from './treatmentPlanSummary';
import { Box } from '@mui/material';
import { SAVE_TREATMENT_PLAN_DATA } from 'src/store/types';
import { useParams } from 'react-router-dom';
import { decrypt } from 'src/lib/encryption';
import { useForm } from 'react-hook-form';
import CustomForm from 'src/components/form';
import { treatmentPlanFormGroups } from './formGroup';
import { CardContent } from '@mui/material';
import useCRUD from 'src/hooks/useCRUD';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import { isEmpty } from 'lodash';
import { successMessage } from 'src/lib/constants';
import { getUpdatedFieldsValue, showSnackbar } from 'src/lib/utils';
import palette from 'src/theme/palette';

const initialData = { isActive: true };

const Summary = ({ refetchData, modalCloseAction, defaultData }) => {
  const params = useParams();
  let { patientId } = params || {};
  patientId = decrypt(patientId);
  const {
    selectedInterventions,
    selectedObjectives,
    selectedGoals,
    selectedBehaviors,
    selectedProblems,
    selectedDiagnosis,
    setActiveStep,
  } = useTreatmentPlan();
  const form = useForm({ mode: 'onChange' });
  const { handleSubmit } = form;

  const [response, , , callTreatmentPlanSaveAPI, clearData] = useCRUD({
    id: SAVE_TREATMENT_PLAN_DATA,
    url: API_URL.treatmentPlan,
    type: isEmpty(defaultData) ? REQUEST_METHOD.post : REQUEST_METHOD.update,
  });

  const onHandleSubmit = useCallback(
    (data) => {
      const treatmentPlanData = {
        patientId,
        diagnoses: selectedDiagnosis,
        problemList: selectedProblems,
        behaviorList: selectedBehaviors,
        goalList: selectedGoals,
        objectiveList: selectedObjectives,
        interventionList: selectedInterventions,
      };
      if (isEmpty(defaultData)) {
        callTreatmentPlanSaveAPI({ data: { ...treatmentPlanData, ...data } });
      } else {
        const updatedFields = getUpdatedFieldsValue(data, defaultData);
        delete updatedFields.id;
        delete updatedFields.patient;
        delete updatedFields.createdBy;
        delete updatedFields.diag;
        delete updatedFields.reviewedBy;
        delete updatedFields.status;

        const { id } = defaultData;
        callTreatmentPlanSaveAPI(
          { ...treatmentPlanData, ...updatedFields },
          `/${id}`
        );
      }
    },
    [callTreatmentPlanSaveAPI]
  );

  useEffect(() => {
    if (!isEmpty(response)) {
      showSnackbar({
        message: isEmpty(defaultData)
          ? successMessage.create
          : successMessage.update,
        severity: 'success',
      });
      clearData();
      refetchData();
      modalCloseAction();
    }
  }, [refetchData, response]);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <div
        style={{
          padding: '12px',
          border: `1px solid ${palette.border.main}`,
          borderRadius: '4px',
        }}
      >
        <TreatmentPlanSummary />
      </div>
      <CardContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <CustomForm
          form={form}
          formGroups={treatmentPlanFormGroups}
          columnsPerRow={1}
          defaultValue={isEmpty(defaultData) ? initialData : defaultData}
        />
      </CardContent>
      <Box>
        <CardActions
          sx={{
            justifyContent: 'flex-end',
            marginTop: '40px',
          }}
        >
          <LoadingButton
            onClick={handleBack}
            variant="outlinedSecondary"
            label="Back"
          />
          <LoadingButton
            onClick={handleSubmit(onHandleSubmit)}
            label="Submit"
          />
        </CardActions>
      </Box>
    </>
  );
};

export default Summary;
