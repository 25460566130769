/* eslint-disable camelcase */
import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import CardContent from '@mui/material/CardContent';

import {
  inputLength,
  noHtmlTagPattern,
  regexAlphanumeric,
  regexCustomText,
  requiredField,
} from 'src/lib/constants';

import Modal from 'src/components/modal';
import CustomForm from 'src/components/form';
import Container from 'src/components/Container';
import { generateUniqueId } from 'src/lib/utils';

const InputTypeOptions = [
  { label: 'Select', value: 'select' },
  { label: 'CheckBox', value: 'checkBox' },
  { label: 'TextArea', value: 'textArea' },
  { label: 'Radio', value: 'radio' },
  { label: 'Date', value: 'date' },
  { label: 'Date & Time', value: 'dateTime' },
  { label: 'Text', value: 'text' },
  { label: 'Slider', value: 'slider' },
  { label: 'MultiSelect', value: 'multiSelect' },
  { label: 'Matrix', value: 'matrix' },
  { label: 'MultiChoice', value: 'multiChoice' },
  { label: 'Signature', value: 'signature' },
  { label: 'Number', value: 'number' },
  { label: 'File', value: 'uploadFile' },
  { label: 'Editor', value: 'editor' },
  { label: 'Placeholder Text', value: 'textLabel' },
];

const profileFieldsTypeInput = [
  { label: 'First Name', value: 'firstName' },
  { label: 'Middle Name', value: 'middleName' },
  { label: 'Last Name', value: 'lastName' },
  { label: 'Email', value: 'email' },
  { label: 'Address', value: 'address' },
];

const profileFieldsTypeRadio = [{ label: 'Gender', value: 'gender' }];
const profileFieldsTypePhone = [{ label: 'Phone', value: 'contact' }];

const fieldValidations = [
  { label: 'Only alphabets', value: 'onlyAlphabet' },
  { label: 'Alphabets and numbers', value: 'alphanumeric' },
  { label: 'Alphabets, numbers and special characters', value: 'commonText' },
  { label: 'Email type', value: 'email' },
  { label: 'Alphabets and spaces', value: 'alphabetAndSpaces' },
];

const formGroups = [
  {
    inputType: 'text',
    name: 'textLabel',
    textLabel: 'Field Label',
    placeholder: 'Enter Field Label',
    required: requiredField,
    pattern: noHtmlTagPattern,
  },
  {
    inputType: 'text',
    name: 'description',
    multiline: true,
    minRows: 3,
    textLabel: 'Field Description',
    placeholder: 'Enter Field description',
    pattern: noHtmlTagPattern,
    maxLength: { ...inputLength.commonTextLength },
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide:
          data.inputType === 'radio' ||
          data.inputType === 'matrix' ||
          data.inputType === 'signature' ||
          data.inputType === 'checkBox' ||
          data.inputType === 'uploadFile'
      }),
    },
  },
  {
    inputType: 'select',
    name: 'inputType',
    label: 'Type',
    valueAccessor: 'value',
    labelAccessor: 'label',
    data: InputTypeOptions,
    required: requiredField,
    colSpan: 0.5,
  },
  {
    inputType:'editor',
    name:'editorValue',
    colSpan:1,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'editor',
      }),
    },
  },
  {
    inputType:'text',
    type:'number',
    textLabel:'Min',
    name:'min',
    colSpan:0.25,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'slider',
      }),
    },
  },
  {
    inputType:'text',
    type:'number',
    textLabel:'Max',
    name:'max',
    colSpan:0.25,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'slider',
      }),
    },
  },
  {
    inputType: 'checkBox',
    name: 'enableScore',
    label: 'Enable Score Calculation',
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'matrix',
      }),
    },
  },
  {
    inputType: 'matrix',
    name: 'matrix',
    textLabel: 'Matrix',
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'matrix',
      }),
    },
  },
  {
    inputType: 'select',
    name: 'profileFieldMapping',
    label: 'Profile Mapping',
    valueAccessor: 'value',
    labelAccessor: 'label',
    data: profileFieldsTypeInput,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'text',
      }),
    },
    colSpan: 0.5,
  },
  {
    inputType: 'select',
    name: 'profileFieldMapping',
    label: 'Profile Mapping',
    valueAccessor: 'value',
    labelAccessor: 'label',
    data: profileFieldsTypeRadio,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'select' && data.inputType !== 'radio',
      }),
    },
    colSpan: 0.5,
  },
  {
    inputType: 'select',
    name: 'profileFieldMapping',
    label: 'Profile Mapping',
    valueAccessor: 'value',
    labelAccessor: 'label',
    data: profileFieldsTypePhone,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'number',
      }),
    },
    colSpan: 0.5,
  },
  {
    inputType: 'tags',
    name: 'options',
    label: 'Options',
    multiple:true,
    optionsParser: (tagOptions) => tagOptions.map((option) => option.label || option),
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide:
          data.inputType === 'text' ||
          data.inputType === 'textArea' ||
          data.inputType === 'date' ||
          data.inputType === 'dateTime' ||
          data.inputType === 'textLabel' ||
          data.inputType === 'slider' ||
          data.inputType === 'checkBox' ||
          data.inputType === 'matrix' ||
          data.inputType === 'signature' ||
          data.inputType === 'number' ||
          data.inputType === 'uploadFile' ||
          data.inputType === 'editor' ||
          !data.inputType,
      }),
    },
  },
  {
    inputType: 'select',
    name: 'validation',
    label: 'Validations',
    valueAccessor: 'value',
    labelAccessor: 'label',
    data: fieldValidations,
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType !== 'text' && data.inputType !== 'textArea',
      }),
    },
  },
  {
    inputType: 'checkBox',
    name: 'mandatory',
    label: 'Required',
    dependencies: {
      keys: ['inputType'],
      calc: (data) => ({
        hide: data.inputType === 'matrix',
      }),
    },
  },
  {
    inputType: 'slider',
    type: 'number',
    name: 'colSpan',
    textLabel: 'Field Width',
    min: 1,
    max: 12,
  },
];

const AddField = ({
  isVisible,
  handleClose,
  handleFormGroups,
  handleEditFormGroups,
  rowIndex,
  sectionIndex,
  formGroupType,
  itemIndex,
  fieldToEdit,
  maxColSpan,
}) => {
  let newFormGroup = [...formGroups];
  if (!formGroupType) {
    newFormGroup.unshift({
      inputType: 'text',
      name: 'title',
      textLabel: 'Section Title',
      placeholder: 'Type section title',
      pattern: regexCustomText,
      maxLength: { ...inputLength.commonTextLength },
    });
  }
  // if (newFormGroup?.[9]?.inputType === 'slider') {
  //   if (formGroupType === 'field' && fieldToEdit?.colSpan) {
  //     newFormGroup[9] = {
  //       ...newFormGroup[9],
  //       max: fieldToEdit?.inputType
  //         ? maxColSpan + fieldToEdit.colSpan
  //         : fieldToEdit.colSpan,
  //     };
  //   } else {
  //     newFormGroup[9] = {
  //       ...newFormGroup[9],
  //       max: formGroupType === 'field' ? maxColSpan : 12,
  //     };
  //   }
  // }
  const form = useForm({ mode: 'onChange' });

  const {
    handleSubmit,
    watch,
    formState: { dirtyFields },
  } = form;

  if (watch('inputType') === 'text' || watch('inputType') === 'textArea') {
    newFormGroup = newFormGroup.map(item => {
      if (item.name === 'validation') {
        return {
          ...item,
          required: requiredField,
        };
      }
      return item;
    });
  }

  if (['select','radio','multiSelect','multiChoice'].includes(watch('inputType'))) {
    newFormGroup = newFormGroup.map(item => {
      if (item.name === 'options') {
        return {
          ...item,
          required: requiredField,
        };
      }
      return item;
    });
  }
  if (watch('inputType')==='slider') {
    newFormGroup = newFormGroup.map(item => {
      if (item.name === 'min') {
        return {
          ...item,
          required: requiredField,
        };
      }
      if (item.name === 'max') {
        return {
          ...item,
          required: requiredField,
        };
      }
      return item;
    });
  }

  const handleSaveMaster = useCallback(
    (data) => {
      if (!isEmpty(dirtyFields)) {
        if (fieldToEdit?.id) {
          handleEditFormGroups({
            data,
            rowIndex,
            itemIndex,
            sectionIndex,
          });
        } else {
          const uniqueID = generateUniqueId();
          handleFormGroups({
            data: { id: uniqueID, name: uniqueID, ...data },
            rowIndex,
            itemIndex,
            sectionIndex,
            formGroupType,
          });
        }
      }
      handleClose();
    },
    [
      dirtyFields,
      fieldToEdit?.id,
      formGroupType,
      handleClose,
      handleEditFormGroups,
      handleFormGroups,
      itemIndex,
      rowIndex,
      sectionIndex,
    ]
  );

  const footer = useMemo(
    () => ({
      leftActions: [
        {
          name: 'Cancel',
          variant: 'text',
          action: handleClose,
          style: { boxShadow: 'unset', color: '#303030' },
        },
        {
          name: 'Save',
          action: handleSubmit(handleSaveMaster),
          style: { marginRight: 16 },
        },
      ],
    }),
    [handleClose, handleSaveMaster, handleSubmit]
  );

  return (
    <Modal
      open={isVisible}
      header={{ title: 'Add Field' }}
      footer={footer}
      onClose={handleClose}
    >
      <Container key={fieldToEdit?.id}>
        <CardContent>
          <CustomForm
            formGroups={newFormGroup}
            columnsPerRow={1}
            defaultValue={fieldToEdit}
            form={form}
          />
        </CardContent>
      </Container>
    </Modal>
  );
};

export default AddField;
