import React from 'react';
import {  Box, CardContent } from '@mui/material';
import Diagnosis from './component/diagnosis';
import Problems from './component/problems';
import Behaviors from './component/behaviors';
import Goals from './component/goals';
import { useTreatmentPlan } from './treatmentPlanContext';
import Objectives from './component/objectives';
import Interventions from './component/interventions';
import Summary from './component/summary';
import palette from 'src/theme/palette';
import Typography from 'src/components/Typography';

const steps = [
  'Diagnosis',
  'Problems',
  'Behaviors',
  'Goals',
  'Objectives',
  'Interventions',
  'Summary',
];

const StepForm = ({ modalCloseAction, refetchData, defaultData }) => {
  const { activeStep } = useTreatmentPlan();

  return (
    <Box sx={{ width: '100%' }}>
      <CardContent sx={{paddingTop:'0px',marginTop:'38px',paddingBottom:'0px'}}>
        <div style={{backgroundColor:palette.background.offBlue,padding:'12px 12px'}}>
      <Typography style={{fontSize:16,fontWeight:600,lineHeight:'20px'}}>
        {activeStep === 6
          ? `${steps[activeStep]}`
          : `Step - ${activeStep + 1} of ${steps.length - 1} (${
              steps[activeStep]
            })`}
      </Typography>
      </div>
      </CardContent>
      <CardContent sx={{borderColor:palette.border.main,borderWidth:'1px'}}>
        {activeStep === steps.length ? (
          <Typography variant="h6" align="center">
            All steps completed - you&apos;re finished
          </Typography>
        ) : (
          <>
            {activeStep === 0 && (
             <Diagnosis
                modalCloseAction={modalCloseAction}
                defaultData={defaultData}
              />
            )}
            {activeStep === 1 && <Problems defaultData={defaultData} />}
            {activeStep === 2 && <Behaviors defaultData={defaultData} />}
            {activeStep === 3 && <Goals defaultData={defaultData} />}
            {activeStep === 4 && <Objectives defaultData={defaultData} />}
            {activeStep === 5 && <Interventions defaultData={defaultData} />}
            {activeStep === 6 && (
              <Summary
                refetchData={refetchData}
                modalCloseAction={modalCloseAction}
                defaultData={defaultData}
              />
            )}
          </>
        )}
      </CardContent>
    </Box>
  );
};

export default StepForm;
