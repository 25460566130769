/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import {
  createContext,
  useEffect
} from 'react';

import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import useAuthUser from 'src/hooks/useAuthUser';
import useQuery from 'src/hooks/useQuery';
import {
  USER_CHAT_LIST
} from 'src/store/types';

export const ChatContext = createContext();
export const ChatContentProvider = ({ children }) => {
  const [user] = useAuthUser();
const [
  response,
  loading,
  chatPage,
  rowsPerPage,
  handlePageChange,
  filters,
  handleFilters,
  sort,
  handleSort,
  handleOnFetchDataList,
] = useQuery({
  listId: USER_CHAT_LIST,
  url: API_URL.chats,
  type: REQUEST_METHOD.get,
  subscribeSocket: true,
  fetchInitial:false,
});

useEffect(()=>{
  if(user){
    handleOnFetchDataList()
  }
},[user]);

  return <ChatContext.Provider value={{response,
    loading,
    chatPage,
    rowsPerPage,
    handlePageChange,
    filters,
    handleFilters,
    sort,
    handleSort,
    handleOnFetchDataList,}}>{children}</ChatContext.Provider>;
};
