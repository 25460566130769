/**
 *
 * ReusableElementArray is a wrapper for a conventional array. This wrapper does not remove elements in
 * the array, it nulls them instead. When an element is nulled, its space is considered free. Thus
 * it can be reused by the next addition to the array. This array never shrinks.
 *
 * @returns {ReusableElementArray} Object
 */

const ReusableElementArray = () => {
  const reusableElementArray = {
    array: [],

    /**
     * isHead returns:
     *  - True: if it is the first not null element in the array.
     *  - False: otherwise. Also if the element is not found.
    */
    isHead(element) {
      for (let i = 0; i < this.array.length; i++) {
        if (this.array[i] !== null) {
          return this.array[i] === element;
        }
      }
      return false;
    },

    /**
     * addElement adds the element in the first free space, i.e. index with value: null.
     * If there is no free space, the element is added at the end of the array.
    */
    addElement(element) {
      for (let i = 0; i < this.array.length; i++) {
        if (this.array[i] === null) {
          this.array[i] = element;
          return;
        }
      }
      this.array.push(element);
    },

    /**
     * removeElement replaces the element by null.
     * If the element is not found, this is a noop.
    */
    removeElement(element) {
      const indexToNull = this.array.findIndex(elem => elem === element);
      if (indexToNull > -1) {
        this.array[indexToNull] = null;
      }
    },
  };
  return reusableElementArray;
};

export default ReusableElementArray;
