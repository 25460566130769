import React from 'react';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Search from 'src/assets/images/Search.png';
import palette from 'src/theme/palette';

const MedicalBillsTable = () => {
  const bills = [
    {
      date: '12/09/2022',
      patient: 'John Doe',
      claimId: '68456IF',
      provider: 'Billing Co.',
      payer: 'New York Health',
      amount: '$80.00',
    },
    {
      date: '11/09/2022',
      patient: 'Jane Smith',
      claimId: '98765HG',
      provider: 'Health Services',
      payer: 'California Care',
      amount: '$150.00',
    },
    {
      date: '10/09/2022',
      patient: 'Bob Johnson',
      claimId: '12345AB',
      provider: 'Medical Billing',
      payer: 'Texas Health',
      amount: '$200.00',
    },
  ];
  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  return (
    <Card
      style={{
        border: '1px solid #E8E8E8',
        margin: '1em 2em',
        padding: '1em 0.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em 1em',
        }}
      >
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
            color: palette.text.dark,
          }}
        >
          Medical Bills
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
          <div>
            <img
              className="hover"
              src={Search}
              alt="search"
              style={{
                width: '24px',
                height: '24px',
                opacity: '70%',
                marginTop: '10px',
              }}
            />
          </div>
          <div style={{}}>
            <FormControl
              sx={{
                padding: '0px 12px',
                gap: '10px',
                borderRadius: '8px',
                border: `1px solid ${palette.background.offGreenTwo}`,
              }}
            >
              <Select
                sx={{
                  '& svg': {
                    color: '#337AB7',
                    marginLeft: '20px',
                  },
                  '& .MuiSelect-outlined': {
                    border: 'none',
                    outline: 'none',
                  },
                  '& .MuiInputBase-root': {
                    border: 'none',
                    padding: '9px 12px',
                  },
                  '& .MuiSelect-select.MuiInputBase-input': {
                    paddingTop: '9px',
                    paddingBottom: '9px',
                  },
                  '& .MuiSelect-select': {},
                  '& .css-vamk89-MuiFormControl-root': {},

                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiTypography-root.MuiTypography-body1': {
                    marginRight: '10px',
                  },
                }}
                displayEmpty
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          color: palette.text.dark,
                          paddingRight: '14px',
                        }}
                      >
                        {' '}
                        By type
                      </Typography>
                    );
                  }

                  return selected.join(', ');
                }}
                // MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Placeholder</em>
                </MenuItem>
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <TableContainer style={{ padding: '0 1em' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Patient</TableCell>
              <TableCell>Claim ID</TableCell>
              <TableCell>Billing Provider</TableCell>
              <TableCell>Payer</TableCell>
              <TableCell>Claim Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((bill, index) => (
              <TableRow key={index}>
                <TableCell>{bill.date}</TableCell>
                <TableCell>{bill.patient}</TableCell>
                <TableCell>{bill.claimId}</TableCell>
                <TableCell>{bill.provider}</TableCell>
                <TableCell>{bill.payer}</TableCell>
                <TableCell>{bill.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MedicalBillsTable;
