import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import LoadingButton from 'src/components/CustomButton/loadingButton';
import CustomForm from 'src/components/form';
import useCRUD from 'src/hooks/useCRUD';
import {
  hourOptions,
  meridianOptions,
  minuteOptions,
  requiredField,
  successMessage
} from 'src/lib/constants';
import { decrypt } from 'src/lib/encryption';
import {
  getUserTimezone,
  showSnackbar
} from 'src/lib/utils';
import { SAVE_MEDICATION_SCHEDULE } from 'src/store/types';
import {
  WiredMasterAutoComplete,
  WiredSelect
} from 'src/wiredComponent/Form/FormFields';

const AddMedicationSchedule = ({defaultData, modalCloseAction,  refetchData = () => {},}) => {
    const params = useParams();
    const form = useForm({ mode: 'onChange' });
    const { handleSubmit, setValue } = form;

    let { patientId, patientMedicationId } = params || {};
    patientId =decrypt(patientId);


    const ordersFormGroups = useMemo(
      () => [
        {
          ...WiredMasterAutoComplete({
            url: API_URL.genericDrug,
            name: 'genericDrug',
            label: 'Generic',
            labelAccessor: 'name',
            valueAccessor: 'id',
            disabled: true,
          }),
        },
        {
          inputType: 'nestedForm',
          name: 'timeSlots',
          label: 'Dosage details for active ingredients',
          textButton: 'Add New',
          required: requiredField,
          columnsPerRow: 1,
          colSpan: 1,
          // isMore: false,
          formGroups: [
            {
              label: 'Start Time',
              colSpan: 1,
              fields: [
                {
                  ...WiredSelect({
                    name: 'startHour',
                    label: 'Start Time',
                    required: requiredField,
                    valueAccessor: 'value',
                    labelAccessor: 'label',
                    url: null,
                    options: hourOptions,
                    labelProps: { style: { height: 18 }, required: false },
                    placeholder: 'Hour',
                    showRadio: false,
                  }),
                  colSpan: 0.22,
                },
                {
                  ...WiredSelect({
                    name: 'startMinute',
                    label: 'Min',
                    // required: requiredField,
                    valueAccessor: 'value',
                    labelAccessor: 'label',
                    url: null,
                    options: minuteOptions,
                    // labelProps: { style: { height: 18 }, required: false },
                    // cstSx: { paddingLeft: '10px !important' },
                    showRadio: false,
                    placeholder: 'Minute',
                  }),
                  colSpan:  0.22,
                },
                {
                  ...WiredSelect({
                    name: 'startMeridien',
                    label: 'Meridien',
                    // required: requiredField,
                    valueAccessor: 'value',
                    labelAccessor: 'label',
                    url: null,
                    options: meridianOptions,
                    gridProps: { paddingLeft: 500 },
                    // cstSx: { paddingLeft: '10px !important' },
                    // labelProps: { style: { height: 18 }, required: false },
                    placeholder: 'AM/PM',
                    showRadio: false,
                  }),
                  colSpan: 0.22,
                },
              ],
            },
          ],
        },
      ],
      []
    );

    
    const [response, , loading, callMedicationScheduleSaveAPI, clearData] = useCRUD({
      id: SAVE_MEDICATION_SCHEDULE,
      url: API_URL.medicationSchedule,
      type: defaultData?.scheduleId ? REQUEST_METHOD.update : REQUEST_METHOD.post,
    });

    function processTimeSlotsToUTC(data) {
      const userTimezone = getUserTimezone(); // Get user's timezone
      const processedData = { ...data, timeSlots: [] };
  
      data.timeSlots.forEach(slot => {
          const hour = parseInt(slot.startHour, 10);
          const minute = parseInt(slot.startMinute, 10);
          const meridien = slot.startMeridien.toUpperCase();
  
          // Adjust hour based on AM/PM
          const adjustedHour = meridien === 'PM' && hour !== 12 ? hour + 12 : hour === 12 && meridien === 'AM' ? 0 : hour;
  
          // Step 1: Create a dayjs object in the user's timezone
          const localTime = dayjs()
          .set('hour', adjustedHour)
          .set('minute', minute)
          .set('second', 0)
          .set('millisecond', 0)
          .tz(userTimezone, true);
         
  
          // Step 2: Convert to UTC time and extract hour, minute, meridian
          const utcTime = localTime.utc();
          const utcHour = utcTime.format('hh'); // UTC hour in 12-hour format
          const utcMinute = utcTime.format('mm'); // UTC minute
          const utcMeridien = utcTime.format('A'); // UTC AM/PM
          // Push the updated slot with UTC time fields into processedData
          processedData.timeSlots.push({
              startHour: utcHour,
              startMinute: utcMinute,
              startMeridien: utcMeridien
          });
      });
  
      return processedData;
  }

  
    const onHandleSubmit = useCallback(
      (data) => {
        const isDefaultData=defaultData?.scheduleId;
        let payload = {};
        if (!isDefaultData) {
          payload = {
            patientId,
            timeSlots: data?.timeSlots,
            patientMedicationItemId: data?.id,
          };
        } else {
          payload ={
            timeSlots: data?.timeSlots,
          }
        }
        if (isEmpty(payload)) {
          showSnackbar({
            message: 'No changes found',
            severity: 'error',
          });
          return;
        }
 
        if (!isDefaultData) {
          const updatedPayoad = processTimeSlotsToUTC(payload)
          callMedicationScheduleSaveAPI({ data: { ...updatedPayoad } });
        } else {
          const updatedPayoad = processTimeSlotsToUTC(payload)
          callMedicationScheduleSaveAPI({ ...updatedPayoad }, `/${defaultData?.scheduleId}`);
        }
      },
      [callMedicationScheduleSaveAPI, defaultData]
    );  

    useEffect(() => {
      if (!isEmpty(response)) {
        showSnackbar({
          message: defaultData?.scheduleId
            ? successMessage.update
            : successMessage.create,
          severity: 'success',
        });
        clearData(true);
        refetchData();
        modalCloseAction();
      }
    }, [refetchData, response, defaultData, clearData, modalCloseAction,patientId]);

    return (
        <Box>
          <CardContent>
            {/* <Box sx={{ mt: 2 }}>
                <LoadingButton
                    onClick={addTimeField}
                    label="Add Another Time"
                />
            </Box> */}
            <CustomForm
              form={form}
              formGroups={ordersFormGroups}
              columnsPerRow={1}
              defaultValue={isEmpty(defaultData) ? {} : defaultData}
            />
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'flex-start',
              paddingLeft: '24px',
              paddingRight: '24px',
            }}
          >
            <LoadingButton
              type= 'fabButton'
              variant="outlinedSecondary"
              onClick={modalCloseAction}
              label="Cancel"
            />
            <LoadingButton
              loading={loading}
              onClick={handleSubmit(onHandleSubmit)}
              label="Submit"
            />
          </CardActions>
        </Box>
      );
}
export default AddMedicationSchedule;
