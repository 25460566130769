import { appointmentStatusCode, dateFormats } from "src/lib/constants";
import { convertWithTimezone, dateFormatter } from "src/lib/utils";
import dayjs from 'dayjs';

export function appointmentJoinButtonCondition(data) {
    const currentDateTime = new Date();
    const date = convertWithTimezone(
        data?.startDateTime,
        { format: dateFormats.MMDDYYYYhhmmA }
      )
    const startDateTime = new Date(date)

    const isStartDateTimePassed = currentDateTime < startDateTime;

    const isDisable=(!(data?.statusCode === appointmentStatusCode.CONFIRMED || data?.statusCode === appointmentStatusCode.ONGOING) || isStartDateTimePassed)
    return isDisable;
  }
  