import React from 'react';
import PropTypes from 'prop-types';
import TypographyMUI from '@mui/material/Typography';

const Typography = ({ children, ...props }) => (
  <TypographyMUI fontFamily="Poppins" {...props} data-testid="typography-test">
    {children}
  </TypographyMUI>
);

Typography.defaultProps = {
  children: <span />,
};

Typography.propTypes = {
  children: PropTypes.node,
};

export default Typography;
