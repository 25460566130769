import { regexCustomText, requiredField } from "src/lib/constants";
import { WiredMasterField } from "src/wiredComponent/Form/FormFields";

export const moduleFormGroups = [
    {
      inputType: 'text',
      type: 'text',
      name: 'name',
      required: requiredField,
      textLabel: 'Name',
      pattern: regexCustomText,
    },
    {
      inputType: 'text',
      type: 'text',
      name: 'route',
      required: requiredField,
      textLabel: 'Route',
      pattern: regexCustomText,
    },
    {
      ...WiredMasterField({
        code: 'permissions',
        filter: { limit: 20 },
        name: 'permissionIds',
        label: 'Permissions',
        labelAccessor: 'name',
        valueAccessor: 'id',
        required: requiredField,
        placeholder: 'Select',
        cache: false,
        multiple: true
      }),
    },
    {
        inputType: 'textArea',
        name: 'description',
        textLabel: 'Description',
        pattern: regexCustomText,
        colSpan: 1,
        // required: requiredField,
    },
  ];