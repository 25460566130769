import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { dateFormats } from 'src/lib/constants';
import { dateFormatter, getFullName } from 'src/lib/utils';
import palette from 'src/theme/palette';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Search from 'src/assets/images/Search.png';
import CreateOrder from 'src/pages/LabRadiology/createOrder';
import ModalComponent from 'src/components/modal';
import useQuery from 'src/hooks/useQuery';
import { LABS_RADIOLOGY_LIST } from 'src/store/types';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import { getLabsRadiologyEditData } from 'src/pages/LabRadiology/labsRadiologyHelper';
import AlertDialog from 'src/components/AlertDialog';
import { SAVE_LABS_RADIOLOGY_DATA } from 'src/store/types';
import { showSnackbar } from 'src/lib/utils';
import { isEmpty } from 'lodash';
import useCRUD from 'src/hooks/useCRUD';
import Events from 'src/lib/events';
import {  successMessage } from 'src/lib/constants';


const LabsTable = ({ labsRadiologies }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultData, setDefaultData] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [labsRadiologyData, setLabsRadiologyData] = useState();
  const [updatedResponse, , , callLabRadiologoDeleteAPI] = useCRUD({
    id: SAVE_LABS_RADIOLOGY_DATA,
    url: API_URL.labsRadiology,
    type: REQUEST_METHOD.update,
  });
  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const handleEditLabsRadiology = (data) => {
    const editData = getLabsRadiologyEditData(data);
    setDefaultData(editData);
    setModalOpen(true);
  };
  const deleteDialogBox = useCallback((data) => {
    setLabsRadiologyData(data);
    setDeleteModalOpen((value) => !value);
  }, []);
  const closeOrderModal = useCallback(() => {
    setModalOpen(false);
    setDefaultData(null);
  }, []);
  const deleteOrder = useCallback(() => {
    if (labsRadiologyData) {
      const { id } = labsRadiologyData;

      callLabRadiologoDeleteAPI({ isDeleted: true }, `/${id}`);
    }
    setDeleteModalOpen((pre) => !pre);
  }, [callLabRadiologoDeleteAPI, labsRadiologyData]);
  const dialogActions = useMemo(
    () => [
      {
        title: 'Cancel',
        action: () => setDeleteModalOpen((current) => !current),
        actionStyle: { color: palette.common.black, padding: '8px' },
        variant: 'secondary',
      },
      {
        title: 'Confirm',
        action: deleteOrder,
        actionStyle: { color: palette.primary.main, padding: '8px' },
        variant: 'secondary',
      },
    ],
    [deleteOrder]
  );
  useEffect(() => {
    if (!isEmpty(updatedResponse)) {
      showSnackbar({
        message: updatedResponse.deletedById ? successMessage.delete : successMessage.update,
        severity: 'success',
      });
      Events.trigger(`REFRESH-PATIENT-DETAIL-${updatedResponse.patientId}`);
    }
  }, [updatedResponse]);
  return (
    <Card
      style={{
        border: '1px solid #E8E8E8',
        margin: '1em 2em',
        padding: '1em 0.5em',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em 1em',
        }}
      >
        <Typography
          style={{
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '20px',
            color: palette.text.dark,
          }}
        >
          Labs
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '36px' }}>
          <div>
            <img
              className="hover"
              src={Search}
              alt="search"
              style={{
                width: '24px',
                height: '24px',
                opacity: '70%',
                marginTop: '10px',
              }}
            />
          </div>
          <div style={{}}>
            <FormControl
              sx={{
                padding: '0px 12px',
                gap: '10px',
                borderRadius: '8px',
                border: `1px solid ${palette.background.offGreenTwo}`,
              }}
            >
              <Select
                sx={{
                  '& svg': {
                    color: '#337AB7',
                    marginLeft: '20px',
                  },
                  '& .MuiSelect-outlined': {
                    border: 'none',
                    outline: 'none',
                  },
                  '& .MuiInputBase-root': {
                    border: 'none',
                    padding: '9px 12px',
                  },
                  '& .MuiSelect-select.MuiInputBase-input': {
                    paddingTop: '9px',
                    paddingBottom: '9px',
                  },
                  '& .MuiSelect-select': {},
                  '& .css-vamk89-MuiFormControl-root': {},

                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '& .MuiTypography-root.MuiTypography-body1': {
                    marginRight: '10px',
                  },
                }}
                displayEmpty
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return (
                      <Typography
                        style={{
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          color: palette.text.dark,
                          paddingRight: '14px',
                        }}
                      >
                        {' '}
                        By type
                      </Typography>
                    );
                  }

                  return selected.join(', ');
                }}
                // MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Placeholder</em>
                </MenuItem>
                {names.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <TableContainer
        component={Paper}
        style={{ height: 250, overflow: 'auto', padding: '0 1em' }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Test</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: 200, overflowY: 'auto' }}>
            {labsRadiologies?.map((labsRadiology, index) => (
              <TableRow key={index}>
                <TableCell>{getFullName(labsRadiology?.patient)}</TableCell>
                <TableCell>{labsRadiology?.laboratoryTests[0]?.name}</TableCell>
                <TableCell>{labsRadiology?.result || 'N/A'}</TableCell>
                <TableCell>
                  {dateFormatter(
                    labsRadiology?.createdAt,
                    dateFormats.MMMDDYYYYHHMMSS
                  )}
                </TableCell>
                <TableCell>
                  {labsRadiology?.sendingFacility?.name || 'N/A'}
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    <EditIcon
                      onClick={() => handleEditLabsRadiology(labsRadiology)}
                      style={{ cursor: 'pointer' }}
                    />
                    {/* <RemoveRedEyeOutlinedIcon /> */}
                    <DeleteIcon
                      onClick={() => deleteDialogBox(labsRadiology)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modalOpen && (
        <ModalComponent
          open={modalOpen}
          header={{
            title: 'Edit Order',
            closeIconAction: closeOrderModal,
          }}
          modalStyle={{width:'100%'}}
          boxStyle={{maxWidth:'1000px',width:'100%'}}
        >
          <CreateOrder
            modalCloseAction={closeOrderModal}
            refetchData={()=>{}}
            defaultData={defaultData}
            fromMain={false}
          />
        </ModalComponent>
      )}
      <AlertDialog
        open={deleteModalOpen}
        content="Are you sure you want to delete?"
        actions={dialogActions}
      />
    </Card>
  );
};

export default LabsTable;
