import React from 'react';
import {
  Card,
  CardMedia,
  Typography,
  Grid,
  Button,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import palette from 'src/theme/palette';
import person from 'src/assets/images/patient.png';
import ShowPatientActivityLog from '../Home/TopCard';
import {
  getFullName,
  getImageUrl,
  dateFormatter,
  getInsurance,
} from 'src/lib/utils';
import { dateFormats } from 'src/lib/constants';
import { capitalize } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  cover: {
    width: 151,
    height: 151,
    borderRadius: '10%',
    padding: '0.5em',
  },
}));
export const PatientInfoCard = ({ patientData }) => {
  const classes = useStyles();

  return (
    <Card
      style={{
        border: '1px solid #E8E8E8',
        margin: '1em 2em',
        padding: '1em 0.5em',
      }}
    >
      <Grid container>
        <Grid item md={3}>
          <CardMedia
            component="img"
            className={classes.cover}
            image={
              patientData?.file?.file
                ? getImageUrl(patientData?.file?.file)
                : person
            }
            alt="Patient Photo"
          />
        </Grid>
        <Grid item md={9}>
          <div>
            <Grid
              container
              style={{ alignItems: 'center', paddingBotton: '0.5em' }}
            >
              <Grid xs={8} md={10}>
                <Typography
                  color={palette.background.main}
                  style={{
                    fontSize: 20,
                    lineHeight: '20px',
                    fontWeight: 600,
                  }}
                >
                  {getFullName(patientData)}
                </Typography>
              </Grid>
              <Grid xs={4} md={2}>
                <Button variant="contained" color="primary">
                  Active
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '0.5em 0' }}>
              <Grid item xs={12} md={4}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Patient ID:
                  </Typography>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                    }}
                  >
                    {patientData?.id}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={3}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Gender:
                  </Typography>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                    }}
                  >
                    {patientData?.sexAtBirth?.name || 'N/A'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Patient Since:
                  </Typography>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                    }}
                  >
                    {dateFormatter(
                      patientData?.createdAt,
                      dateFormats.MMDDYYYY
                    )}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '0.5em 0' }}>
              <Grid item xs={12} md={7}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Phone:
                  </Typography>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                    }}
                  >
                    {patientData?.phone ||
                      patientData?.homePhone ||
                      patientData?.preferredPhone ||
                      patientData?.workPhone ||
                      patientData?.textMessagePhone ||
                      'N/A'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div style={{ display: 'flex' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Email:
                  </Typography>
                  <Tooltip title={patientData?.email || 'N/A'}>
                    <Typography
                      color={palette.text.dark}
                      style={{
                        fontSize: 14,
                        lineHeight: '20px',
                        fontWeight: 400,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        marginLeft:4,
                      }}
                    >
                      {patientData?.email}
                    </Typography>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
            <Grid container style={{ padding: '0.5em 0' }}>
              <Grid item xs={12} md={7}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Billing Type:
                  </Typography>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                    }}
                  >
                    {capitalize(patientData?.billingType) || 'N/A'}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <div style={{ display: 'flex' }}>
                  <Typography
                    color={palette.text.offWhite}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                    }}
                  >
                    Medical Insurance:
                  </Typography>
                  <Tooltip title={getInsurance(patientData?.insurance) || 'N/A'}>
                  <Typography
                    color={palette.text.dark}
                    style={{
                      fontSize: 14,
                      lineHeight: '20px',
                      fontWeight: 400,
                      marginLeft:4,
                      overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                  >
                    {getInsurance(patientData?.insurance) || 'N/A'}
                  </Typography>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div>
        <ShowPatientActivityLog patientId={patientData?.id} />
      </div>
    </Card>
  );
};
