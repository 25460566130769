import { CardActions, CardContent } from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { API_URL, REQUEST_METHOD } from 'src/api/constants';
import Box from 'src/components/Box';
import Container from 'src/components/Container';
import LoadingButton from 'src/components/CustomButton/loadingButton';
import TableTextRendrer from 'src/components/TableTextRendrer';
import Typography from 'src/components/Typography';
import CustomForm from 'src/components/form';
import ModalComponent from 'src/components/modal';
import useCRUD from 'src/hooks/useCRUD';
import useReduxState from 'src/hooks/useReduxState';
import {
  bankTransferType,
  cardType,
  initialBillingData,
  regTextArea,
  regexAlphanumeric,
  regexUrl,
  requiredField,
  roleTypes,
  successMessage,
} from 'src/lib/constants';
import { showSnackbar } from 'src/lib/utils';
import {
  ENCOUNTER_BILLING_DATA,
  SAVE_PATIENT_ENCOUNTER_BILLING,
} from 'src/store/types';
import {
  WiredLocationField,
  WiredMasterAutoComplete,
  WiredMasterField,
  WiredStaffField,
} from 'src/wiredComponent/Form/FormFields';
import { v4 } from 'uuid';
import IcdBrowser from '../../../Medication/icdBrowser';
import CustomProcedureForm from './customProcedureForm';
import DiagnosisTable from './diagnosisTable';
import palette from 'src/theme/palette';
import CardPayment from './cardPayment';

const calcBankAccount =(data)=>{
  if(data.bankTransferType==='check'){
    return {hide:true}
  }
return {hide:false}
}
const calcBankCheck =(data)=>{
  if(data.bankTransferType==='check'){
    return {hide:false}
  }
return {hide:true}
}

const summaryOfChargesGroups = [
  {
    inputType: 'text',
    name: 'subTotal',
    type: 'number',
    textLabel: 'Subtotal',
    disabled: true,
    colSpan: 0.2,
  },
  {
    inputType: 'text',
    name: 'tip',
    type: 'number',
    textLabel: 'Tips/Gratuity',
    colSpan: 0.2,
  },
  {
    inputType: 'text',
    name: 'insuranceSubmittedAmount',
    type: 'number',
    textLabel: 'Amount submited to insurance',
    colSpan: 0.3,
  },
  {
    inputType: 'text',
    name: 'previousBalance',
    type: 'number',
    textLabel: 'Previous Balance',
    colSpan: 0.3,
  },
  {
    inputType: 'text',
    name: 'total',
    type: 'number',
    textLabel: 'Total',
    colSpan: 0.5,
    disabled:true,
  },
];

const defaultData ={
  bankTransferType:bankTransferType?.[0]?.value

}
const totalCalc = (data, index, form) => {
  const price = form.getValues(`encounterProcedureCodes.${index}.price`) || 0;
  const qty = form.getValues(`encounterProcedureCodes.${index}.qty`) || 1;
  const discPer = form.getValues(`encounterProcedureCodes.${index}.discPer`);
  const discAmt = form.getValues(`encounterProcedureCodes.${index}.discAmt`);
  const total = price * qty;
  const overAllDisc = discPer ? (total * discPer) / 100 : discAmt || 0;
  form.setValue(`encounterProcedureCodes.${index}.total`, total - overAllDisc);

  return { reFetch: false };
};
const SuperBillForm = ({ onClose, patientId, encounterId } = {}) => {
  const [icdBrowserModal, setIcdBrowserModal] = useState(false);
  const [showCustomProcedure, setShowCustomProcedure] = useState(false);
  const [cardPaymentData,setCardPaymentData] = useState(null);

  const [billingData, setBillingData] = useReduxState(
    `Patient_Encounter-Billing-Data`,
    initialBillingData
  );
  const form = useForm({ mode: 'onChange' });

  const [
    encounterBillingSaveResponse,
    ,
    encounterBillingSaveLoading,
    callEncounterBillingSaveAPI,
    clearEncounterBillingSaveData,
  ] = useCRUD({
    id: SAVE_PATIENT_ENCOUNTER_BILLING,
    url: API_URL.patientEncounterBilling,
    type: !billingData?.id ? REQUEST_METHOD.post : REQUEST_METHOD.update,
  });

  const [
    patientEncounterBillingData,
    ,
    patientEncounterBillingLoading,
    callPatientEncounterBillingAPI,
    clearPatientEncounterBilling,
  ] = useCRUD({
    id: `${ENCOUNTER_BILLING_DATA}-${encounterId}`,
    url: `${API_URL.patientEncounterBilling}/encounter/${encounterId}`,
    type: REQUEST_METHOD.get,
  });

  useEffect(() => {
    if (!isEmpty(patientEncounterBillingData)) {
      const clonedBilling = cloneDeep(patientEncounterBillingData);
      clonedBilling.encounterProcedureCodes =
        clonedBilling?.encounterProcedureCodes?.map((item) => {
          if (item.addOnFields) {
            item = { ...item, ...item.addOnFields };
            delete item.addOnFields;
          }
          return item;
        }) || [];
      const data = {
        patientId: clonedBilling.patientId,
        primaryProviderId: clonedBilling.primaryProviderId,
        referenceProviderId: clonedBilling.referenceProviderId,
        visitDate: clonedBilling.visitDate || null,
        locationId: clonedBilling.locationId,
        statusCode: clonedBilling.statusCode,
        comment: clonedBilling.comment || '',
        encounterDiagnosis: clonedBilling.encounterDiagnosis || [],
        encounterDiagnosisSnomeds:
          clonedBilling.encounterDiagnosisSnomeds || [],
        encounterProcedureCodes: clonedBilling.encounterProcedureCodes || [],
        id: clonedBilling.id,
      };
      setBillingData(data);
      clearPatientEncounterBilling(true);
    }
  }, [patientEncounterBillingData]);

  useEffect(() => {
    if (encounterId) {
      callPatientEncounterBillingAPI();
    }
  }, [encounterId, billingData.id]);

  useEffect(() => {
    if (!isEmpty(encounterBillingSaveResponse)) {
      showSnackbar({
        message: !billingData.id
          ? 'Encounter Bill Created'
          : 'Encouter Bill Updated',
        severity: 'success',
      });
      clearEncounterBillingSaveData(true);
      onClose();
    }
  }, [encounterBillingSaveResponse, billingData]);

  const { handleSubmit,watch } = form;

 useEffect(() => {
    const subscription = watch((value, { name, type }) =>{
      if(name.includes('encounterProcedureCodes')){
        const subTotal = value?.encounterProcedureCodes?.reduce((acc,item)=>{
          acc=acc+(item?.total || 0);
          return acc;
         },0)
         form.setValue('subTotal',subTotal)
      }
      if(['tip','subTotal','previousBalance','insuranceSubmittedAmount'].includes(name)){
        const total = parseInt(value.tip || 0)+parseInt(value.subTotal || 0)+parseInt(value.previousBalance || 0) + parseInt(value.insuranceSubmittedAmount  || 0);
        form.setValue('total',total)
      }
    }
    )
    return () => subscription.unsubscribe()
  }, [watch])

  const onHandleSubmit = useCallback(
    (data) => {
      data.patientId = patientId;
      delete data.procedureCode;
      if (!billingData.id) {
        data.encounterId = encounterId;
        callEncounterBillingSaveAPI({ data });
      } else {
        delete data.id;
        callEncounterBillingSaveAPI({ ...data }, `/${billingData.id}`);
      }
    },
    [billingData, patientId, encounterId]
  );

  const handleIcdBrowser = useCallback(() => {
    setIcdBrowserModal(true);
  }, []);
  const closeIcdBrowserModal = useCallback(() => {
    setIcdBrowserModal(false);
  }, []);

  const handleShowCustomProcedure = useCallback(() => {
    setShowCustomProcedure(true);
  }, []);
  const closeCustomProcedure = useCallback(() => {
    setShowCustomProcedure(false);
  }, []);

  const handleIcdBrowserSave = useCallback(
    (selectedPatientMedicationDiagnosis) => {
      const array1 = selectedPatientMedicationDiagnosis;
      const array2 = form.getValues(`encounterDiagnosis`) || [];

      // Combine the arrays
      const combinedArray = array1.concat(array2);

      // Use reduce to filter out duplicates
      const uniqueArray = combinedArray.reduce((accumulator, current) => {
        // Check if the current id already exists in the accumulator
        if (!accumulator.some((item) => item.id === current.id)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      form.setValue(`encounterDiagnosis`, uniqueArray, {
        shouldValidate: true,
      });
      closeIcdBrowserModal();
    },
    []
  );

  const onSearchProcedure = useCallback((data) => {
    const array1 = [data];
    const array2 = form.getValues(`encounterProcedureCodes`) || [];

    // Combine the arrays
    const combinedArray = array2.concat(array1);

    // Use reduce to filter out duplicates
    const uniqueArray = combinedArray.reduce((accumulator, current = {}) => {
      // Check if the current id already exists in the accumulator
      if (!accumulator.some((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    form.setValue(`encounterProcedureCodes`, uniqueArray, {
      shouldValidate: true,
    });
  }, []);

  const handleCoustomProcedureSave = (data) => {
    const existingData = form.getValues(`encounterProcedureCodes`) || [];
    existingData.push({ ...data, id: `new_${v4()}` });
    form.setValue(`encounterProcedureCodes`, existingData, {
      shouldValidate: true,
    });
    closeCustomProcedure();
  };


  const handleTakePayment = useCallback(()=>{
    const amount = form.getValues('cardAmount')
    setCardPaymentData({amount});
  },[]);

  const closeCardPayment = useCallback((e,r)=>{
    console.log(">>>>>>>>>>>>>>>>e",e,r)
    setCardPaymentData(false);
  },[])

  const paymentGroups = useMemo(()=>[
    {
      inputType: 'date',
      type: 'text',
      name: 'paymentDate',
      label: 'Date of Payment',
      colSpan: 1,
    },
    {
      inputType: 'text',
      name: 'cash',
      type: 'number',
      textLabel: 'Payment (Cash) $',
      colSpan: 0.5,
    },
    {
      inputType: 'text',
      name: 'prePaidCash',
      type: 'number',
      textLabel: 'Prepaid Amount',
      colSpan: 0.25,
    },
    {
      inputType: 'wiredSelect',
      options: [
        { name: 'Prepaid', value: 'prepaid' },
      ],
      name: 'prepaidType',
      labelAccessor: 'name',
      valueAccessor: 'value',
      colSpan: 0.25,
      label:'Type'
    },
  
    //
    {
      inputType: 'text',
      name: 'bankAmount',
      type: 'number',
      textLabel: 'Payment (Bank) $',
      colSpan: 0.25,
    },
    {
      inputType: 'wiredSelect',
      options: bankTransferType,
      name: 'bankTransferType',
      labelAccessor: 'name',
      valueAccessor: 'value',
      colSpan: 0.25,
      label:'Transfer Type'
    },
    {
      inputType: 'text',
      name: 'bankName',
      type: 'text',
      textLabel: 'Bank Name',
      colSpan: 0.25,
      dependencies: {
        keys: ['bankTransferType'],
        calc: calcBankAccount,
      },
    },
    {
      inputType: 'text',
      name: 'holderName',
      type: 'text',
      textLabel: 'Holder Name',
      colSpan: 0.25,
      dependencies: {
        keys: ['bankTransferType'],
        calc: calcBankAccount,
      },
    },
    {
      inputType: 'text',
      name: 'accountNo',
      type: 'number',
      textLabel: 'Account No.',
      colSpan: 0.5,
      dependencies: {
        keys: ['bankTransferType'],
        calc: calcBankAccount,
      },
    },
    {
      inputType: 'text',
      name: 'ifsc',
      type: 'text',
      textLabel: 'IFSC code',
      pattern: regexAlphanumeric,
      colSpan: 0.5,
      dependencies: {
        keys: ['bankTransferType'],
        calc: calcBankAccount,
      },
    },
    {
      inputType: 'text',
      name: 'checkNo',
      type: 'text',
      textLabel: 'Check No.',
      colSpan: 0.5,
      pattern: regexAlphanumeric,
      dependencies: {
        keys: ['bankTransferType'],
        calc: calcBankCheck,
      },
    },
    //
  
    {
      inputType: 'text',
      name: 'cardAmount',
      type: 'number',
      textLabel:()=> <div style={{display:'flex',flexWrap:'wrap'}}><Typography style={{fontSize:'12px',lineHeight:'18px'}}> Payment (Card) $</Typography> <div><LoadingButton onClick={handleTakePayment} sx={{height:'auto',padding:'1px 10px'}} label={'Take Card Payment'}/></div></div>,
      colSpan: 0.25,
    },
    {
      inputType: 'wiredSelect',
      options: cardType,
      name: 'cardType',
      labelAccessor: 'name',
      valueAccessor: 'value',
      colSpan: 0.25,
      label:'Type'
    },
    {
      inputType: 'text',
      name: 'cardNo',
      type: 'number',
      textLabel: 'Last 4',
      colSpan: 0.5,
    },
  
  
  ],[handleTakePayment]);



  const formGroups = useMemo(
    () => [
      {
        ...WiredStaffField({
          name: 'primaryProviderId',
          label: 'Provider',
          colSpan: 0.5,
          placeholder: 'Select',
          required: requiredField,
        }),
      },
      {
        ...WiredStaffField({
          name: 'referenceProviderId',
          label: 'Reference Provider',
          colSpan: 0.5,
          placeholder: 'Select',
        }),
      },
      {
        inputType: 'date',
        type: 'text',
        name: 'visitDate',
        label: 'Date of Visit',
        required: requiredField,
        colSpan: 0.5,
      },
      {
        ...WiredLocationField({
          name: 'locationId',
          label: 'Location',
          colSpan: 0.5,
          placeholder: 'Select',
          filter: { limit: 20 },
        }),
      },
      {
        ...WiredMasterAutoComplete({
          url: API_URL.diagnosisIcd,
          label: 'ICD-10 code or name',
          name: 'encounterDiagnosis',
          colSpan: 0.7,
          placeholder: 'Enter ICD-10 code or name',
          labelAccessor: 'name',
          valueAccessor: 'id',
          multiple: true,
          showDescription: true,
          descriptionAccessor: 'description',
        }),
      },

      {
        component: () => (
          <div
            style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}
          >
            <LoadingButton label="BROWSER" onClick={handleIcdBrowser} />
          </div>
        ),
        colSpan: 0.3,
        cstSx: {
          paddingLeft: '10px !important',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      },
      {
        ...WiredMasterAutoComplete({
          url: API_URL.diagnosisSnometCT,
          label: 'SnomedCT name',
          name: 'encounterDiagnosisSnomeds',
          colSpan: 0.7,
          placeholder: 'Enter name or descriptionm',
          labelAccessor: 'name',
          valueAccessor: 'id',
          multiple: true,
          showDescription: true,
          descriptionAccessor: 'description',
        }),
      },
      {
        component: ({ form }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
              flex: 1,
            }}
          >
            <DiagnosisTable form={form} />
          </div>
        ),
        colSpan: 1,
        cstSx: { height: '100%', display: 'flex', alignItems: 'center' },
      },

      {
        component: () => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
              justifyContent: 'space-between',
              gap: 10,
              flex: 1,
            }}
          >
            <div>
              <Typography style={{ fontSize: 14, fontWeight: 600 }}>
                Procedure Codes (enter E/M, CPT and HCPCS procedure codes here)
              </Typography>
            </div>
            <div>
              <LoadingButton
                onClick={handleShowCustomProcedure}
                label={
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 8,
                      alignItems: 'center',
                    }}
                  >
                    <Typography>+</Typography>{' '}
                    <Typography>Custom PROCEDURE CODE</Typography>
                  </div>
                }
              />
            </div>
          </div>
        ),
        colSpan: 1,
        cstSx: {
          paddingLeft: '10px !important',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
      },
      {
        inputType: 'wiredSelect',
        options: [
          { name: 'Standard Procedure Code', value: 'standardProcedureCode' },
          { name: 'Custom Procedure Code', value: 'customProcedureCode' },
        ],
        name: 'procedureCodeType',
        labelAccessor: 'name',
        valueAccessor: 'value',
        colSpan: 0.5,
      },
      {
        ...WiredMasterAutoComplete({
          url: API_URL.procedureCode,
          label: ' ',
          name: 'procedureCode',
          colSpan: 0.5,
          placeholder: 'Enter code or name',
          labelAccessor: 'name',
          valueAccessor: 'id',
          onChange: onSearchProcedure,
          showDescription: true,
          descriptionAccessor: 'description',
        }),
      },
      {
        inputType: 'nestedTableV2',
        name: 'encounterProcedureCodes',
        label: ' ',
        textButton: 'Add New',
        columnsPerRow: 8,
        gridGap: 1,
        isMore: false,
        formGroups: [
          {
            label: 'Rank',
            component: ({ index, getValues }) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <TableTextRendrer>{index + 1}</TableTextRendrer>
              </div>
            ),
            colSpan: 0.3,
            cstSx: {
              paddingLeft: '10px !important',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            },
          },
          {
            label: 'Codes',
            component: ({ index, getValues }) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <TableTextRendrer>
                  {(getValues(`encounterProcedureCodes`) || [])?.[index]?.name}
                </TableTextRendrer>
              </div>
            ),
            colSpan: 0.3,
            cstSx: {
              paddingLeft: '10px !important',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            },
          },
          {
            label: 'Description',
            component: ({ index, getValues }) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1rem',
                }}
              >
                <TableTextRendrer>
                  {
                    (getValues(`encounterProcedureCodes`) || [])?.[index]
                      ?.description
                  }
                </TableTextRendrer>
              </div>
            ),
            colSpan: 0.3,
            cstSx: {
              paddingLeft: '10px !important',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            },
          },
          {
            inputType: 'text',
            name: 'qty',
            type: 'number',
            textLabel: 'Qty',
            label: 'Qty',
            placeholder: '',
            sx: { width: '60px' },
          },
          {
            label: 'Modifiers',
            fields: [
              {
                inputType: 'text',
                type: 'number',
                name: 'modifier1',
                required: requiredField,
                textLabel: '',
                sx: { width: '60px', marginRight: '4px' },
                maxLength: { value: 4 },
                placeholder: ' ',
              },
              {
                inputType: 'text',
                type: 'number',
                name: 'modifier2',
                required: requiredField,
                textLabel: '',
                sx: { width: '60px', marginRight: '4px' },
                maxLength: { value: 4 },
                placeholder: ' ',
              },
              {
                inputType: 'text',
                type: 'number',
                name: 'modifier3',
                required: requiredField,
                textLabel: '',
                sx: { width: '60px', marginRight: '4px' },
                maxLength: { value: 4 },
                placeholder: ' ',
              },
              {
                inputType: 'text',
                type: 'number',
                name: 'modifier4',
                required: requiredField,
                textLabel: '',
                sx: { width: '60px', marginRight: '4px' },
                maxLength: { value: 4 },
                placeholder: ' ',
              },
            ],
          },
          {
            inputType: 'text',
            type: 'number',
            name: 'price',
            required: requiredField,
            label: 'Price',
            placeholder: ' ',
            sx: { width: '70px' },
            maxLength: { value: 4 },
          },
          {
            inputType: 'text',
            type: 'number',
            name: 'discPer',
            label: 'Addtnl. Disc',

            textLabel: '',
            sx: { width: '80px', marginRight: '4px' },
            maxLength: { value: 4 },
            placeholder: ' ',
            InputProps: { endAdornment: '%' },
          },
          {
            inputType: 'text',
            type: 'number',
            name: 'discAmt',
            label: 'Addtnl. Disc Amt',

            textLabel: '',
            sx: { width: '100px', marginRight: '4px' },
            maxLength: { value: 4 },
            placeholder: ' ',
            InputProps: { endAdornment: '$' },
          },
          {
            inputType: 'text',
            type: 'number',
            name: 'total',
            required: requiredField,
            label: 'Total',
            placeholder: ' ',
            sx: { width: '70px' },
            maxLength: { value: 4 },
            dependencies: {
              keys: ['price', 'qty', 'discAmt', 'discPer'],
              calc: totalCalc,
              listenAllChanges: true,
            },
          },
        ],
      },
      {
        component: () => (
          <Typography style={{ fontWeight: 'bolder' }}>
            Summary of Charges
          </Typography>
        ),
      },
      ...summaryOfChargesGroups,
      {
        inputType: 'textArea',
        name: 'comment',
        textLabel: 'Comment',
        colSpan: 1,
        pattern: regTextArea,
      },
      {
        component: () => (
          <Typography style={{ fontWeight: 'bolder' }}>
            Payment
          </Typography>
        ),
      },
      ...paymentGroups,
      {
        component: () => (
          <div style={{backgroundColor:palette.text.radio,padding:5,borderRadius:5}}>
          <Typography style={{ fontSize: '12px' }}>
            For Card Payment: click on 'Take Card Payment' and the Balance Amount will populate automatically in the Card Payment pop up, if payment is successful the paid amount field will update automatically 
          </Typography>
          </div>
        ),
      },  

      {
        inputType: 'text',
        name: 'balance',
        type: 'number',
        textLabel: 'Balance $',
        colSpan: 0.5,
      },
      {
        inputType: 'text',
        name: 'note',
        type: 'text',
        textLabel: ' ',
        colSpan: 1,
      },

      {
        ...WiredMasterField({
          code: 'billing_status',
          filter: { limit: 20 },
          name: 'statusCode',
          label: 'Set Status',
          labelAccessor: 'name',
          valueAccessor: 'code',
          colSpan: 0.5,
          placeholder: 'Select',
        }),
      },
      {
        component: () => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '1rem',
              flex: 1,
              gap: 15,
            }}
          >
            <LoadingButton onClick={() => {}} label={'PRINT'} />
            <LoadingButton
              variant="outlinedSecondary"
              onClick={() => {}}
              label="PAY NOW"
            />
          </div>
        ),
        colSpan: 0.5,
        cstSx: { height: '100%', display: 'flex', alignItems: 'center' },
      },
    ],
    []
  );
  return (
    <Container loading={patientEncounterBillingLoading}>
      <CardContent>
        <CustomForm
          form={form}
          formGroups={formGroups}
          defaultValue={isEmpty(billingData)?defaultData: billingData}
        />
      </CardContent>
      <CardActions
        sx={{
          justifyContent: 'flex-start',
          paddingLeft: '24px',
          paddingRight: '24px',
        }}
      >
        <LoadingButton
          variant="outlinedSecondary"
          onClick={onClose}
          label="Cancel"
        />
        <LoadingButton
          onClick={handleSubmit(onHandleSubmit)}
          loading={
            encounterBillingSaveLoading || patientEncounterBillingLoading
          }
          label={billingData?.id ? 'Update' : 'Save'}
        />
      </CardActions>
      {icdBrowserModal && (
        <ModalComponent
          open
          header={{
            title: 'ICD Browser',
            closeIconAction: closeIcdBrowserModal,
          }}
          modalStyle={{ width: '100%' }}
        >
          <Box>
            <IcdBrowser
              modalCloseAction={closeIcdBrowserModal}
              onSave={handleIcdBrowserSave}
              patientId={patientId}
            />
          </Box>
        </ModalComponent>
      )}

      {showCustomProcedure && (
        <ModalComponent
          open
          header={{
            title: 'Add Custom Procedure',
            closeIconAction: closeCustomProcedure,
          }}
          modalStyle={{ width: '100%' }}
        >
          <Box>
            <CustomProcedureForm
              onClose={closeCustomProcedure}
              onSave={handleCoustomProcedureSave}
            />
          </Box>
        </ModalComponent>
      )}
      {cardPaymentData &&  <ModalComponent
      open={true}
      header={{
        title: `Online Payment form`,
        closeIconAction: closeCardPayment,
      }}
      modalStyle={{width:'100%'}}
      shouldCloseOnBackdropClick={false}
    >
     <CardPayment defaultData={cardPaymentData}/>
    </ModalComponent>}
    </Container>
  );
};

export default React.memo(SuperBillForm);
