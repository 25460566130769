import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useEffect } from 'react';
import { Container } from '@mui/system';
import { DashboardLayout } from './layouts/dashboard';
import { SimpleLayout } from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import Account from './pages/Account/Account';
import SignUp from './pages/SignUp';
import ResetPasswordPage from './pages/RessetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import Bookings from './pages/Booking';
import BookingSettingContainer from './pages/BookingSetting';
import WaitingRoom from './pages/VideoCall';
import PatientList from './pages/Patient';
import Invoice from './pages/Invoice';
import Message from './pages/Message/Message';
import Fax from './pages/Fax';
import LabRadiologyList from './pages/LabRadiology';
// import LabRadiologyResultList from './pages/LabRadiologyResult';
import LabRadiologyResultList from './pages/LabRadiology/labReport';
import LabRequest from './pages/LabRadiology/labRequest';
import Staff from './pages/Staff';

////////////////////////// PATIENT PORTAL IMPORTS/////////////
import Dashboard from './pages/PatientPortal/Dashboard';
import Appointment from './pages/PatientPortal/Appointments';
import Forms from './pages/PatientPortal/Forms';
import Medication from './pages/PatientPortal/Medications';
import MedicationView from './pages/PatientPortal/Medications/medicationView';
import LabRadiology from './pages/PatientPortal/LabRadiology';
import PatientLabReport from './pages/PatientPortal/LabRadiology/labReport';
import PatientLabRequestData from './pages/PatientPortal/LabRadiology/labRequest';
///////////////////////////////////////////////////////////////


// import DashboardAppPage from './pages/DashboardAppPage';
// import Forms from './pages/Forms';
// import Payment from './pages/Payment';
// import AppointmentHistory from './pages/AppointmentHistory';
// import BookingForm from './pages/Booking/BookingForm';
// import BookingSlots from './pages/Booking/BookingSlots';
// import AddClinic from './pages/admin/AddClinic';
// import Teams from './pages/Team';
// import AddTeamMember from './pages/Team/AddTeamMember';
// import MasterFormPage from './pages/Masters/masterForm';
// import AddPateint from './pages/Patient/AddPatient';

import { UI_ROUTES } from './lib/routeConstants';
import { roleTypes } from './lib/constants';
import BookingPortal from './pages/PatientPortal';
import PatientAppointmentList from './pages/Patient/components/Appointment';
import PatientNotes from './pages/Patient/components/Comments';
import { getUserRole } from './lib/utils';
import Order from './pages/Order';
import Home from './pages/Patient/components/Home/Home';
import Clinic from './pages/Clinic';
import ClinicAdmin from './pages/Clinic/ClinicAdmin';
import GraphTracking from './pages/Patient/components/ActivityLogs/GraphTracking';
import EndCall from './pages/VideoCall/Components/EndCall';
import OpenTalkVideoRoom from './pages/VideoCall/OpenTalk/OpenTalkVideoRoom';
import useAuthUser from './hooks/useAuthUser';
import ChatLists from './pages/Chat';
import EPrescription from './pages/PatientPortal/EPrescription';
import FormHome from './pages/FormBuilder/MyFormsTabs';
import FormTabsShared from './pages/SharedForms/FormTabsShared';
import PatientFormList from './pages/PatientPortal/Forms';
import DashboardHome from './pages/SuperAdminDashboard/DashboardHome';
import Masters from './pages/Masters';
import AppointmentsBoard from './pages/AppointmentsBoard';
import ClinicMedInstructionList from './pages/MedInstrcution';
import ClientHome from './pages/Home';
import Page404Section from './pages/Page404Section';
import FormBuilderPDF from './pages/FormBuilder/PDF';
import SettingsTab from './pages/Settings/SettingsTab';
import Encounter from './pages/Encounter';
import FormLibraryPDF from './pages/FormBuilder/FromLibraryPdf';
import AddStaff from './pages/Staff/addStaff';
import BookingSettings from './pages/BookingSettings';
import PatientAllergiesList from './pages/PatientPortal/Allergies';
import ZoomSessionScreen from './pages/ZoomSession';
// ----------------------------------------------------------------------

const allRoute = [
  { path: UI_ROUTES.accounts, element: <Account /> },
  { path: UI_ROUTES.booking, element: <Bookings /> },
  { path: UI_ROUTES.board, element: <AppointmentsBoard /> },
  { path: UI_ROUTES.clinics, element: <Clinic />, isNested: true },
  {
    path: UI_ROUTES.clinic,
    element: <ClinicAdmin />,
    isNested: true,
  },
  { path: UI_ROUTES.masters, element: <Masters /> },
  {
    path: UI_ROUTES.bookingSetting,
    element: <BookingSettingContainer />,
  },
  { path: UI_ROUTES.patient, element: <PatientList />, isNested: true },
  { path: UI_ROUTES.appointments, element: <PatientAppointmentList /> },
  { path: UI_ROUTES.invoice, element: <Invoice /> },
  { path: UI_ROUTES.messages, element: <Message /> },
  { path: UI_ROUTES.singleChat, element: <Message /> },
  { path: UI_ROUTES.singlePatientChat, element: <Message /> },

  {
    path: UI_ROUTES.shared,
    element: <PatientFormList shared />,
    isNested: true,
  },
  { path: UI_ROUTES.fax, element: <Fax /> },
  { path: UI_ROUTES.files, element: <Message /> },
  { path: UI_ROUTES.chat, element: <ChatLists />, isNested: true },

  { path: UI_ROUTES.formBuilder, element: <FormHome /> },
  // { path: UI_ROUTES.forms, element: <PatientFormList />, isNested: true },
  // { path: UI_ROUTES.order, element: <Order /> },
  // { path: UI_ROUTES.patientHome, element: <Home /> },
  // { path: UI_ROUTES.graphTracking, element: <GraphTracking /> },
  // { path: UI_ROUTES.prescription, element: <EPrescription />, isNested: true },
  // { path: UI_ROUTES.sharedForms, element: <FormTabsShared /> },
  // { path: UI_ROUTES.adminDashboard, element: <DashboardHome /> },
  // { path: UI_ROUTES.medInstruction, element: <ClinicMedInstructionList /> },



  { path: UI_ROUTES.scheduling, element: <Bookings /> },
  { path: UI_ROUTES.encounters, element: <Encounter />,isNested: true },
  { path: UI_ROUTES.labRadiology, element: <LabRadiologyList /> },
  { path: UI_ROUTES.medicalBilling, element: <Page404Section /> },
  { path: UI_ROUTES.analyticsAndReporting, element: <Page404Section /> },
  { path: UI_ROUTES.messages, element: <Page404Section /> },
  { path: UI_ROUTES.alerts, element: <Page404Section /> },
  { path: UI_ROUTES.tasks, element: <Page404Section /> },
  { path: UI_ROUTES.systemSettings, element: <SettingsTab /> }, // setting
  { path: UI_ROUTES.adminDashboard, element: <DashboardHome /> },
  { path: UI_ROUTES.staff, element: <Staff /> },
  { path: UI_ROUTES.addStaff, element: <AddStaff /> },
  { path: UI_ROUTES.editStaff, element: <AddStaff /> },
  { path: UI_ROUTES.labRadiologyResult, element: <LabRadiologyResultList /> },
  { path: UI_ROUTES.labRequest, element: <LabRequest /> },
  { path: UI_ROUTES.patientLabReport, element: <LabRadiologyResultList />},
  { path: UI_ROUTES.patientLabRequest, element: <LabRequest />},

  /////////////////////////////Booking setting route////////////////////
  { path: UI_ROUTES.bookingSettings, element: <BookingSettings />},


  ////////////////////////PATIENT ROUTE/////////////////////////////
  { path: UI_ROUTES.patientDashboard, element: <Dashboard />},
  { path: UI_ROUTES.patientMssages, element: <Message />},
  { path: UI_ROUTES.patientBooking, element: <Appointment /> },
  { path: UI_ROUTES.patientSharedForms, isNested: true, element: <Forms />},
  { path: UI_ROUTES.patientMedicationData, element: <Medication />},
  { path: UI_ROUTES.patientAllergyData, element: <PatientAllergiesList />},

  { path: UI_ROUTES.patientViewMedication, element: <MedicationView />},
  { path: UI_ROUTES.patientLabRadiologyData, element: <LabRadiology />},
  { path: UI_ROUTES.patientLabRadiologyResultData, element: <PatientLabReport />},
  { path: UI_ROUTES.patientLabRadiologyRequestData, element: <PatientLabRequestData />},

  /////////////////////////////////////////////////////////////////
];

export const superAdminRoutes = [
  UI_ROUTES.accounts,
  UI_ROUTES.bookingSetting,
  UI_ROUTES.clinics,
  UI_ROUTES.adminDashboard,
];
export const clinicAdminRoutes = [
  // UI_ROUTES.adminDashboard,
  // UI_ROUTES.accounts,
  // UI_ROUTES.bookingSetting,
  // UI_ROUTES.clinic,
  // UI_ROUTES.booking,
  // UI_ROUTES.patient,
  // UI_ROUTES.masters,
  // UI_ROUTES.fax,
  // UI_ROUTES.formBuilder,
  // UI_ROUTES.order,
  // UI_ROUTES.chat,
  // UI_ROUTES.sharedForms,
  // UI_ROUTES.graphTracking,
  // UI_ROUTES.board,
  // UI_ROUTES.medInstruction,
  // UI_ROUTES.adminDashboard,

  UI_ROUTES.patient,

  UI_ROUTES.adminDashboard,
  UI_ROUTES.accounts,
  UI_ROUTES.scheduling,
  UI_ROUTES.encounters,
  UI_ROUTES.labRadiology,
  UI_ROUTES.medicalBilling,
  UI_ROUTES.analyticsAndReporting,
  UI_ROUTES.messages,
  UI_ROUTES.singleChat,
  UI_ROUTES.alerts,
  UI_ROUTES.tasks,
  UI_ROUTES.systemSettings,
  UI_ROUTES.formBuilder,
  UI_ROUTES.fax,
  UI_ROUTES.staff,
  UI_ROUTES.addStaff,
  UI_ROUTES.editStaff,
  UI_ROUTES.labRadiologyResult,
  UI_ROUTES.labRequest,
  UI_ROUTES.patientLabReport,

];
////////////////////////PATIENT ROUTES//////////////////////////////////
export const patientUserRoutes = [
  UI_ROUTES.patientDashboard,
  UI_ROUTES.patientMssages,
  UI_ROUTES.patientBooking,
  UI_ROUTES.patientSharedForms,
  UI_ROUTES.accounts,
  UI_ROUTES.patientMedicationData,
  UI_ROUTES.patientAllergyData,
  UI_ROUTES.patientViewMedication,
  UI_ROUTES.patientLabRadiologyData,
  UI_ROUTES.patientLabRadiologyResultData,
  UI_ROUTES.singlePatientChat,
  UI_ROUTES.patientLabRadiologyRequestData,
  UI_ROUTES.zoomSession,

  // UI_ROUTES.scheduling,
  // UI_ROUTES.encounters,
  // UI_ROUTES.labRadiology,
  // UI_ROUTES.medicalBilling,
  // UI_ROUTES.analyticsAndReporting,
  // UI_ROUTES.alerts,
  // UI_ROUTES.tasks,
];///////////////////////////////////////////////////////////////////

export const assistantRoutes = [...clinicAdminRoutes];

export const practitionerRoutes = [
  UI_ROUTES.patient,

  UI_ROUTES.adminDashboard,
  UI_ROUTES.accounts,
  UI_ROUTES.scheduling,
  UI_ROUTES.encounters,
  UI_ROUTES.labRadiology,
  UI_ROUTES.medicalBilling,
  UI_ROUTES.analyticsAndReporting,
  UI_ROUTES.messages,
  UI_ROUTES.singleChat,
  UI_ROUTES.alerts,
  UI_ROUTES.tasks,
  UI_ROUTES.formBuilder,
  UI_ROUTES.fax,
  UI_ROUTES.addStaff,
  UI_ROUTES.editStaff,
  UI_ROUTES.labRadiologyResult,
  UI_ROUTES.labRequest,
  UI_ROUTES.patientLabReport,
  UI_ROUTES.bookingSettings,
  UI_ROUTES.zoomSession,
];

export const patientRoutes = [...patientUserRoutes];

export const getInitialRoute = (role) => {
  if (role === roleTypes.superAdmin) return UI_ROUTES.adminDashboard;
  if (role === roleTypes.patient) return patientRoutes[0];

  return UI_ROUTES.adminDashboard;
};

const getAllowedRoute = (role) => {
  let routes;
  if (role === roleTypes.superAdmin)
    routes = allRoute.filter((route) => superAdminRoutes.includes(route?.path));
  else if (role === roleTypes.patient)
    routes = allRoute.filter((route) => patientRoutes.includes(route?.path));
  else if (role === roleTypes.clinicAdmin)
    routes = allRoute.filter((route) =>
      clinicAdminRoutes.includes(route?.path)
    );
  else if (role === roleTypes.practitioner)
    routes = allRoute.filter((route) =>
      practitionerRoutes.includes(route?.path)
    );
  else
    routes = allRoute.filter((route) => assistantRoutes.includes(route?.path));

  return routes?.map((route) => ({
    path: route.isNested ? `${route.path}/*` : route.path,
    element: route.element,
  }));
};

export default function Router() {
  const userRole = getUserRole();
  const routes = useRoutes([
    {
      path: UI_ROUTES.dashboard,
      element: <DashboardLayout />,
      children: [
        {
          element: <Navigate to={getInitialRoute(userRole)} />,
          index: true,
        },
        ...getAllowedRoute(userRole),
      ],
    },
  { path: UI_ROUTES.zoomSession, element: <ZoomSessionScreen />},


    { path: UI_ROUTES.joinRoom, element: <OpenTalkVideoRoom /> },
    { path: UI_ROUTES.waitingroom, element: <WaitingRoom /> },
    { path: UI_ROUTES.endVideoCall, element: <EndCall /> },
    {
      path: UI_ROUTES.login,
      element: <LoginPage />,
    },
    {
      path: UI_ROUTES.signup,
      element: <SignUp />,
    },
    {
      path: UI_ROUTES.resetpassword,
      element: <ResetPasswordPage />,
    },
    {
      path: UI_ROUTES.forgotpassword,
      element: <ForgotPasswordPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={UI_ROUTES.dashboard} />, index: true },
        { path: UI_ROUTES.NOTFound, element: <Page404 /> },
        // { path: '*', element: <Navigate to={UI_ROUTES.NOTFound} /> },
      ],
    },

//
{
  path: UI_ROUTES.clientHome,
  element: <ClientHome />,
},

    {
      path: UI_ROUTES.bookings,
      element: <BookingPortal />,
    },
    {
      path: UI_ROUTES.printForm,
      element: <FormBuilderPDF />,
    },
    {
      path: UI_ROUTES.formLibrary,
      element: <FormLibraryPDF />,
    },
    
    {
      path: '*',
      element: <NotFoundHandler />,
    },
  ]);

  return routes;
}

const NotFoundHandler = () => {
  const [user, , , , , validateToken] = useAuthUser();

  useEffect(() => {
    validateToken();
  }, []);

  if (user) {
    return <Navigate to={UI_ROUTES.NOTFound} />;
  }

  return <Container loading />;
};
