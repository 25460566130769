/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { FormLabel } from '@mui/material';

import Box from 'src/components/Box';
import CustomForm from 'src/components/form';
import ActionButton from 'src/components/ActionButton';
import { Iconify } from 'src/components/iconify';
import palette from 'src/theme/palette';
import AddField from './components/AddField';
import SectionTitleModal from './components/SectionTitleModal';
import './builder.scss';

const FieldsRow = ({
  rows,
  index,
  sectionIndex,
  rowLength,
  form,
  gridGap,
  handleOpen,
  handleDeleteFormGroup,
  columnsPerRow,
  moveFormGroups,
}) => {
  const ref = useRef();

  const [{ isDragging }, drag] = useDrag({
    type: 'formGroups',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'formGroups',
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      if (dragIndex !== hoverIndex) {
        moveFormGroups(dragIndex, hoverIndex);
      }
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div
      // key={index}
      ref={ref}
      style={{
        display: 'flex',
        gap: gridGap,
        alignItems: 'center',
        cursor: 'move',
        opacity: isDragging ? 0 : 1,
      }}
    >
      <CustomForm
        form={form}
        columnsPerRow={rowLength}
        formGroups={rows}
        // eslint-disable-next-line react/no-unstable-nested-components
        fieldWrapper={(Field, itemIndex, item) => (props) =>
          (
            <div
              style={{ width: '100%' }}
              role="presentation"
              className="editable-field"
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                handleOpen({
                  item: { item, itemIndex },
                  val: 'formGroup',
                  type: 'field',
                  rowIndex: index,
                  sectionIndex,
                  rowLength,
                });
              }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <DragIndicatorIcon
                  className="drag-icon"
                  sx={{ fontSize: '36px', color: palette.grey[600] }}
                />
                <Field
                  {...props}
                  preview
                  style={{ cursor: 'pointer', pointerEvents: 'none' }}
                />
              </div>
              <Iconify
                icon="eva:close-outline"
                sx={{
                  width: 20,
                  height: 20,
                  cursor: 'pointer',
                  margin: 'auto',
                }}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleDeleteFormGroup({
                    sectionIndex,
                    rowIndex: index,
                    itemIndex,
                  });
                }}
              />
            </div>
          )}
      />
      {rowLength < 12 && (
        <div
          role="presentation"
          onClick={() =>
            handleOpen({
              val: 'formGroup',
              type: 'field',
              rowIndex: index,
              sectionIndex,
              rowLength,
              item: { item: { colSpan: 12 - rowLength } },
            })
          }
          style={{
            flexBasis: `calc(94% / ${
              12 - rowLength ? columnsPerRow / (12 - rowLength) : columnsPerRow
            } - ${gridGap})`,
            textAlign: 'center',
            flexShrink: 1,
            padding: '6px',
            border: '1px solid  #A5AAAE',
            borderRadius: '4px',
            fontSize: 'inherit',
            color: 'grey',
            cursor: 'pointer',
          }}
        >
          Add Field +
        </div>
      )}
    </div>
  );
};

const SectionItem = ({
  sectionGroups,
  sectionIndex,
  gridGap,
  handleOpen,
  handleOnEditSectionTitle,
  handleDeleteFormGroup,
  form,
  columnsPerRow,
  moveSection,
  formGroups,
  setFormGroups,
}) => {
  const ref = useRef();

  const [{ isDragging }, drag] = useDrag({
    type: 'sections',
    item: { sectionIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: 'sections',
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.sectionIndex;
      const hoverIndex = sectionIndex;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      if (dragIndex !== hoverIndex) {
        moveSection(item.sectionIndex, sectionIndex);
      }
      // eslint-disable-next-line no-param-reassign
      item.sectionIndex = hoverIndex;
    },
  });

  drag(drop(ref));

  const moveFormGroups = useCallback(
    (fromIndex, toIndex) => {
      const parsedFormGroups = [...formGroups];
      const draggedRow = parsedFormGroups[sectionIndex].fields[fromIndex];
      parsedFormGroups[sectionIndex].fields.splice(fromIndex, 1);
      parsedFormGroups[sectionIndex].fields.splice(toIndex, 0, draggedRow);
      setFormGroups(parsedFormGroups);
    },
    [formGroups, sectionIndex, setFormGroups]
  );

  if (Array.isArray(sectionGroups)) {
    const rowLength = sectionGroups.reduce(
      (accumulator, currentItem) => accumulator + currentItem.colSpan,
      0
    );
    return (
      <FieldsRow
        rows={sectionGroups}
        sectionIndex={sectionIndex}
        rowLength={rowLength}
        form={form}
        gridGap={gridGap}
        handleOpen={handleOpen}
        handleDeleteFormGroup={handleDeleteFormGroup}
        columnsPerRow={columnsPerRow}
      />
    );
  }

  return (
    <div
      ref={ref}
      style={{
        // display: 'flex',
        // flex: 1,
        gap: gridGap,
        alignItems: 'center',
        marginBottom: '19px',
        cursor: 'move',
        opacity: isDragging ? 0 : 1,
      }}
    >
      <div
        key={sectionIndex}
        style={{
          padding: '18px',
          flex: 1,
          backgroundColor: 'white',
          overflow: 'auto',
        }}
      >
        <Grid lineSeparator={!!sectionGroups?.title}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '8px',
              alignItems:'center'
            }}
          >
            <div style={{ padding: '8px',display:'flex',flexDirection:'column' }}>
              <span>{sectionGroups?.title ?? null}</span>
              <FormLabel sx={{fontSize:'12px' }}>{sectionGroups?.sectionDescription ?? null}</FormLabel>
            </div>
            <Box style={{ display: 'flex' }}>
              <ActionButton
                variant="outlined"
                onClick={() =>
                  handleOpen({
                    val: 'formGroup',
                    type: 'row',
                    sectionIndex,
                    item: { item: { colSpan: 12 } },
                  })
                }
              >
                Add Field
              </ActionButton>
              <Box sx={{ display: 'flex', margin: 'auto', marginLeft: '12px' }}>
                <Iconify
                  icon="uil:edit"
                  cursor="pointer"
                  sx={{ width: 20, height: 20 }}
                  onClick={() =>
                    handleOnEditSectionTitle({
                      sectionIndex,
                      sectionGroups,
                    })
                  }
                />
                <Iconify
                  icon="eva:close-outline"
                  cursor="pointer"
                  sx={{ width: 20, height: 20 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFormGroup({ sectionIndex });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        {sectionGroups?.fields?.map((rowGroup, index) => {
          const rowLength = rowGroup?.reduce(
            (accumulator, currentItem) => accumulator + currentItem.colSpan,
            0
          );
          return (
            <FieldsRow
              key={rowGroup?.[0]?.id}
              rows={rowGroup}
              index={index}
              sectionIndex={sectionIndex}
              rowLength={rowLength}
              form={form}
              gridGap={gridGap}
              handleOpen={handleOpen}
              handleDeleteFormGroup={handleDeleteFormGroup}
              columnsPerRow={columnsPerRow}
              moveFormGroups={moveFormGroups}
            />
          );
        })}
      </div>
    </div>
  );
};

const Builder = ({
  formGroups,
  columnsPerRow,
  gridGap = '10px',
  setFormGroups,
  handleFormGroups = () => {},
  handleDeleteFormGroup = () => {},
  // handleDragUp = () => {},
  // handleDragDown = () => {},
  handleEditFormGroups = () => {},
}) => {
  const form = useForm();
  const [open, setOpen] = useState('');
  const [indexes, setIndexes] = useState({});
  const [formGroupType, setFormGroupsType] = useState('');
  const [sectionTitle, setSectionTitle] = useState({});
  const [maxColSpan, setMaxColSpan] = useState(12);
  const [fieldToEdit, setFieldToEdit] = useState();

  const handleOpen = useCallback(
    ({ val, rowIndex, sectionIndex, type, title, rowLength, item ,sectionDescription}) => {
      setFieldToEdit(item);
      setIndexes({ rowIndex, sectionIndex });
      setFormGroupsType(type);
      setMaxColSpan(12 - rowLength);
      setOpen(val);
      setSectionTitle({ title , sectionDescription});
    },
    []
  );

  const handleClose = () => setOpen(false);

  const handleOnEditSectionTitle = useCallback(
    ({ sectionIndex, sectionGroups }) => {
      handleOpen({
        val: 'section',
        sectionIndex,
        title: sectionGroups?.title,
        sectionDescription:sectionGroups?.sectionDescription
      });
    },
    [handleOpen]
  );

  const moveSection = useCallback(
    (fromIndex, toIndex) => {
      const fromCard = formGroups[fromIndex];
      const newFormGroups = [...formGroups];
      newFormGroups.splice(fromIndex, 1);
      newFormGroups.splice(toIndex, 0, fromCard);
      setFormGroups(newFormGroups);
    },
    [formGroups, setFormGroups]
  );

  return (
    <div className="formBuilder-container">
      {formGroups?.map((sectionGroups, sectionIndex) => (
        <SectionItem
          key={sectionGroups?.id}
          sectionGroups={sectionGroups}
          sectionIndex={sectionIndex}
          gridGap={gridGap}
          handleOpen={handleOpen}
          handleOnEditSectionTitle={handleOnEditSectionTitle}
          handleDeleteFormGroup={handleDeleteFormGroup}
          form={form}
          columnsPerRow={columnsPerRow}
          moveSection={moveSection}
          formGroups={formGroups}
          setFormGroups={setFormGroups}
        />
      ))}
      {open === 'section' ? (
        <SectionTitleModal
          open={!!open}
          handleClose={handleClose}
          handleFormGroups={handleFormGroups}
          sectionIndex={indexes?.sectionIndex}
          defaultValue={sectionTitle}
          type="edit"
          handleEditFormGroups={handleEditFormGroups}
        />
      ) : open === 'formGroup' ? (
        <AddField
          isVisible={!!open}
          handleClose={handleClose}
          rowIndex={indexes?.rowIndex}
          itemIndex={fieldToEdit?.itemIndex}
          handleFormGroups={handleFormGroups}
          sectionIndex={indexes?.sectionIndex}
          formGroupType={formGroupType}
          maxColSpan={maxColSpan}
          fieldToEdit={fieldToEdit?.item}
          handleEditFormGroups={handleEditFormGroups}
        />
      ) : null}
      <div
        role="presentation"
        onClick={() =>
          handleOpen({ val: 'formGroup', item: { item: { colSpan: 12 } } })
        }
        style={{
          flex: 1,
          cursor: 'pointer',
          height: 'fit-content',
          padding: '6px',
          border: '1px solid  #A5AAAE',
          borderRadius: '4px',
          fontSize: 'inherit',
          color: 'grey',
          textAlign: 'center',
        }}
      >
        Add Section
      </div>
    </div>
  );
};

export default Builder;
